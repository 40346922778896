// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Mutex from "../../libs/Mutex.js";
import * as Network from "../../libs/Network.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as AppSchema from "../AppSchema.js";
import * as ServerSyncFiles from "./syncsteps/ServerSyncFiles.js";
import * as ServerSyncLevels from "./syncsteps/ServerSyncLevels.js";
import * as ServerSyncAnswers from "./syncsteps/ServerSyncAnswers.js";
import * as ServerSyncFindings from "./syncsteps/ServerSyncFindings.js";
import * as ServerSyncPipeline from "./common/ServerSyncPipeline.js";
import * as ConnectionIndicator from "../common/views/ConnectionIndicator.js";
import * as ServerSyncDocuments from "./syncsteps/ServerSyncDocuments.js";
import * as ServerSyncQuestions from "./syncsteps/ServerSyncQuestions.js";
import * as ServerSyncLevelTypes from "./syncsteps/ServerSyncLevelTypes.js";
import * as ServerSyncPermissions from "./syncsteps/ServerSyncPermissions.js";
import * as ServerSyncCompanyForms from "./syncsteps/ServerSyncCompanyForms.js";
import * as ServerSyncStylingRules from "./syncsteps/ServerSyncStylingRules.js";
import * as ServerSyncUserProfiles from "./syncsteps/ServerSyncUserProfiles.js";
import * as ServerSyncLevelFindings from "./syncsteps/ServerSyncLevelFindings.js";
import * as ServerSyncR4SChecklists from "./syncsteps/ServerSyncR4SChecklists.js";
import * as ServerSyncVesselModules from "./syncsteps/ServerSyncVesselModules.js";
import * as ServerSyncR4SChecklistsItem from "./syncsteps/ServerSyncR4SChecklistsItem.js";
import * as ServerSyncSireVettingPeriods from "./syncsteps/ServerSyncSireVettingPeriods.js";
import * as ServerSyncNotificationEntries from "./syncsteps/ServerSyncNotificationEntries.js";
import * as ServerSyncSire2RoviqLocations from "./syncsteps/ServerSyncSire2RoviqLocations.js";
import * as ServerSyncSire2VettingPeriods from "./syncsteps/ServerSyncSire2VettingPeriods.js";
import * as ServerSyncSubmissionsInspections from "./syncsteps/ServerSyncSubmissionsInspections.js";

var views = [
  AppSchema.Inspections,
  AppSchema.Procedures
];

var views$1 = [AppSchema.Sections];

var steps = [
  [
    "Sync with the permissions API endpoint",
    ServerSyncPermissions.$$do
  ],
  [
    "Sync with user profiles API endpoint",
    ServerSyncUserProfiles.$$do
  ],
  [
    "Sync with findings API endpoint",
    ServerSyncFindings.$$do
  ],
  [
    "Sync with the styling rules API endpoint",
    ServerSyncStylingRules.$$do
  ],
  [
    "Sync with the company forms API endpoint",
    ServerSyncCompanyForms.$$do
  ],
  [
    "Update the Submission's inspections",
    ServerSyncSubmissionsInspections.$$do
  ],
  [
    "Clean up of Inspections and Procedures",
    ServerSyncPipeline.MakeStandardCleanup({
          views: views
        }).$$do
  ],
  [
    "Sync with the levels API endpoint ",
    ServerSyncLevels.$$do
  ],
  [
    "Sync with the levels types API endpoint ",
    ServerSyncLevelTypes.$$do
  ],
  [
    "Sync with the questions API endpoint ",
    ServerSyncQuestions.$$do
  ],
  [
    "Sync with the documents API endpoint",
    ServerSyncDocuments.$$do
  ],
  [
    "Clean up of Sections after fetching documents",
    ServerSyncPipeline.MakeStandardCleanup({
          views: views$1
        }).$$do
  ],
  [
    "Sync with the answers API endpoint",
    ServerSyncAnswers.$$do
  ],
  [
    "Sync with the sire vetting periods API endpoint",
    ServerSyncSireVettingPeriods.$$do
  ],
  [
    "Sync with the sire vetting periods API endpoint (2.0)",
    ServerSyncSire2VettingPeriods.$$do
  ],
  [
    "Sync with the sire roviq locations API endpoint (2.0)",
    ServerSyncSire2RoviqLocations.$$do
  ],
  [
    "Sync with the findings API endpoint ",
    ServerSyncLevelFindings.$$do
  ],
  [
    "Sync with the notifications API endpoint ",
    ServerSyncNotificationEntries.$$do
  ],
  [
    "Sync with the R4S checklist API endpoint",
    ServerSyncR4SChecklists.$$do
  ],
  [
    "Sync with the R4S checklist items API endpoint",
    ServerSyncR4SChecklistsItem.$$do
  ],
  [
    "Update vessel modules",
    ServerSyncVesselModules.$$do
  ],
  [
    "Trigger update of files",
    ServerSyncFiles.$$do
  ]
];

var Pipeline = ServerSyncPipeline.MakeChainPipeline({
      steps: steps
    });

function action(param) {
  var vessels = param.vessels;
  var user = param.user;
  Network.QueueManager.clear();
  return ConnectionIndicator.show(function () {
              return Prelude.PromisedResult.map(Pipeline.$$do({
                              user: user,
                              vessels: vessels,
                              initial: AppSchema.make(),
                              final: AppSchema.make()
                            }), (function (param) {
                            return param.final;
                          }));
            });
}

var Executor = {
  name: "SyncVessels",
  action: action
};

var include = Mutex.MakeMutexQueue(Executor);

var name = include.name;

var action$1 = include.action;

var $$do = include.$$do;

export {
  Pipeline ,
  Executor ,
  name ,
  action$1 as action,
  $$do ,
}
/* Pipeline Not a pure module */
