// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "./tree/Level.js";
import * as Answer from "./tree/Answer.js";
import * as Rating from "./survey/Rating.js";
import * as Vessel from "./vessel/Vessel.js";
import * as $$Comment from "./comments/Comment.js";
import * as Finding from "./tree/Finding.js";
import * as LevelId from "./tree/LevelId.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Section from "./vessel/Section.js";
import * as AnswerId from "./tree/AnswerId.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "./tree/Question.js";
import * as VesselId from "./vessel/VesselId.js";
import * as Component from "./vessel/Component.js";
import * as FindingId from "./tree/FindingId.js";
import * as LevelType from "./tree/LevelType.js";
import * as LocalFile from "../libs/LocalFile.js";
import * as Procedure from "./survey/Procedure.js";
import * as ProfileId from "./accounts/ProfileId.js";
import * as SectionId from "./vessel/SectionId.js";
import * as Deficiency from "./tasks/Deficiency.js";
import * as Inspection from "./survey/Inspection.js";
import * as NewComment from "./comments/NewComment.js";
import * as QuestionId from "./tree/QuestionId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CompanyForm from "./company_forms/CompanyForm.js";
import * as ComponentId from "./vessel/ComponentId.js";
import * as LevelTypeId from "./tree/LevelTypeId.js";
import * as ProcedureId from "./survey/ProcedureId.js";
import * as UserProfile from "./accounts/UserProfile.js";
import * as VesselStaff from "./vessel/VesselStaff.js";
import * as AttachmentId from "./attachment_chunks/AttachmentId.js";
import * as DeficiencyId from "./tasks/DeficiencyId.js";
import * as InspectionId from "./survey/InspectionId.js";
import * as R4SChecklist from "./risk4sea/R4SChecklist.js";
import * as CompanyFormId from "./company_forms/CompanyFormId.js";
import * as NewInspection from "./survey/NewInspection.js";
import * as R4SChecklistId from "./risk4sea/R4SChecklistId.js";
import * as UpdatedComment from "./comments/UpdatedComment.js";
import * as DraftInspection from "./survey/DraftInspection.js";
import * as LevelSubmission from "./tree/LevelSubmission.js";
import * as ProcedureUpdate from "./survey/ProcedureUpdate.js";
import * as FutureAttachment from "./attachment_chunks/FutureAttachment.js";
import * as MarkdownDocument from "./markdown/MarkdownDocument.js";
import * as PlannedProcedure from "./survey/PlannedProcedure.js";
import * as LevelSubmissionId from "./tree/LevelSubmissionId.js";
import * as NotificationEntry from "./notifications/NotificationEntry.js";
import * as SireVettingPeriod from "./sire/SireVettingPeriod.js";
import * as MarkdownDocumentId from "./markdown/MarkdownDocumentId.js";
import * as Sire2VettingPeriod from "./sire/Sire2VettingPeriod.js";
import * as NotificationEntryId from "./notifications/NotificationEntryId.js";
import * as Sire2RoviqLocations from "./sire/Sire2RoviqLocations.js";
import * as SireVettingPeriodId from "./sire/SireVettingPeriodId.js";
import * as Sire2VettingPeriodId from "./sire/Sire2VettingPeriodId.js";
import * as CompanyFormSubmission from "./company_forms/CompanyFormSubmission.js";
import * as ReadNotificationEntry from "./notifications/ReadNotificationEntry.js";
import * as CompanyFormSubmissionId from "./company_forms/CompanyFormSubmissionId.js";

function make() {
  return {
          vessels: VesselId.$$Map.make(),
          sections: SectionId.$$Map.make(),
          components: ComponentId.$$Map.make(),
          procedures: ProcedureId.$$Map.make(),
          plannedProcedures: ProcedureId.$$Map.make(),
          inspections: InspectionId.$$Map.make(),
          deficiencies: DeficiencyId.$$Map.make(),
          ratingStylingRules: Rating.StylingRule.Identifier.$$Map.make(),
          newComments: $$Comment.Identifier.$$Map.make(),
          updatedComments: $$Comment.Identifier.$$Map.make(),
          userProfiles: ProfileId.$$Map.make(),
          newUserProfiles: ProfileId.$$Map.make(),
          newInspections: InspectionId.$$Map.make(),
          draftInspections: InspectionId.$$Map.make(),
          companyForms: CompanyFormId.$$Map.make(),
          companyFormSubmissions: CompanyFormSubmissionId.$$Map.make(),
          newCompanyFormSubmissions: CompanyFormSubmissionId.$$Map.make(),
          draftCompanyFormSubmissions: CompanyFormSubmissionId.$$Map.make(),
          documents: MarkdownDocumentId.$$Map.make(),
          levels: LevelId.$$Map.make(),
          levelTypes: LevelTypeId.$$Map.make(),
          levelSubmissions: LevelSubmissionId.$$Map.make(),
          newLevelSubmissions: LevelSubmissionId.$$Map.make(),
          draftLevelSubmissions: LevelSubmissionId.$$Map.make(),
          files: LocalFile.UUID.$$Map.make(),
          questions: QuestionId.$$Map.make(),
          answers: AnswerId.$$Map.make(),
          newAnswers: AnswerId.$$Map.make(),
          findings: FindingId.$$Map.make(),
          procedureUpdates: ProcedureId.$$Map.make(),
          notificationEntries: NotificationEntryId.$$Map.make(),
          readNotificationEntries: NotificationEntryId.$$Map.make(),
          futureAttachments: AttachmentId.$$Map.make(),
          sireVettingPeriods: SireVettingPeriodId.$$Map.make(),
          sire2VettingPeriods: Sire2VettingPeriodId.$$Map.make(),
          staffers: VesselStaff.Identifier.$$Map.make(),
          r4schecklists: R4SChecklistId.$$Map.make(),
          sire2RoviqLocations: Sire2RoviqLocations.Identifier.$$Map.make()
        };
}

var Core = {
  make: make
};

function MakeView(T) {
  var Id = T.Id;
  var map = T.map;
  var all = function (state) {
    return Id.$$Map.valuesToArray(T.map(state));
  };
  var forEach = function (state, fn) {
    Curry._2(Prelude.$$Array.forEach, all(state), fn);
  };
  var isEmpty = function (state) {
    return Id.$$Map.isEmpty(T.map(state));
  };
  var isNotEmpty = function (state) {
    return !isEmpty(state);
  };
  var update = function (state, item) {
    var eq = Prelude.default(T.equal, Caml_obj.equal);
    var collection = T.map(state);
    var existing = Id.$$Map.get(collection, T.key(item));
    if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
      return ;
    } else {
      return Id.$$Map.set(collection, T.key(item), item);
    }
  };
  var updateMany = function (state, items) {
    Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
            return update(state, extra);
          }));
    return state;
  };
  var remove = function (state, item) {
    Id.$$Map.remove(T.map(state), T.key(item));
  };
  var get = function (state, key) {
    var collection = T.map(state);
    return Id.$$Map.get(collection, key);
  };
  var getActions = function (initial, $$final) {
    var eq = Prelude.default(T.equal, Caml_obj.equal);
    var initial$1 = T.map(initial);
    var $$final$1 = T.map($$final);
    var removing = Id.$$Set.diff(Id.$$Set.fromArray(Id.$$Map.keysToArray(initial$1)), Id.$$Set.fromArray(Id.$$Map.keysToArray($$final$1)));
    var $$new = Curry._2(Prelude.$$Array.keepMap, Id.$$Map.toArray($$final$1), (function (param) {
            var value = param[1];
            var current = Id.$$Map.get(initial$1, param[0]);
            if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
              return ;
            } else {
              return Caml_option.some(value);
            }
          }));
    return Curry._2(Prelude.$$Array.concat, Id.$$Set.toArray(removing).map(function (id) {
                    return {
                            TAG: "Delete",
                            _0: id
                          };
                  }), $$new.map(function (r) {
                    return {
                            TAG: "Save",
                            _0: r
                          };
                  }));
  };
  var clear = function (state) {
    Id.$$Map.clear(map(state));
  };
  return {
          Model: T.Model,
          Id: Id,
          key: T.key,
          map: map,
          equal: T.equal,
          all: all,
          get: get,
          update: update,
          updateMany: updateMany,
          remove: remove,
          getActions: getActions,
          forEach: forEach,
          clear: clear,
          isEmpty: isEmpty,
          isNotEmpty: isNotEmpty,
          getImages: T.getImages
        };
}

function key(record) {
  return record.id;
}

function map(state) {
  return state.sections;
}

var equal = (function (i1, i2) {
    return Caml_obj.equal(Section.normalizeURLs(i1), Section.normalizeURLs(i2));
  });

function getImages(state) {
  return Section.getImages(SectionId.$$Map.valuesToArray(state.sections));
}

var T_Model = {
  clear: Section.clear,
  save: Section.save,
  $$delete: Section.$$delete,
  remove: Section.remove,
  IdComparator: Section.IdComparator,
  Id: Section.Id
};

var T_Id = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: SectionId.make,
  $$null: SectionId.$$null,
  zero: SectionId.zero,
  $$Option: SectionId.$$Option,
  Comparator: SectionId.Comparator,
  Hasher: SectionId.Hasher,
  $$Map: SectionId.$$Map,
  $$Set: SectionId.$$Set,
  MutableSet: SectionId.MutableSet,
  Route: SectionId.Route,
  $$Array: SectionId.$$Array,
  SortArray: SectionId.SortArray
};

var Id = T_Id;

function all(state) {
  return Id.$$Map.valuesToArray(state.sections);
}

function forEach(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all(state), fn);
}

function isEmpty(state) {
  return Id.$$Map.isEmpty(state.sections);
}

function isNotEmpty(state) {
  return !isEmpty(state);
}

function update(state, item) {
  var eq = Prelude.default(equal, Caml_obj.equal);
  var collection = state.sections;
  var existing = Id.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id.$$Map.set(collection, item.id, item);
  }
}

function updateMany(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update(state, extra);
        }));
  return state;
}

function remove(state, item) {
  Id.$$Map.remove(state.sections, item.id);
}

function get(state, key) {
  var collection = state.sections;
  return Id.$$Map.get(collection, key);
}

function getActions(initial, $$final) {
  var eq = Prelude.default(equal, Caml_obj.equal);
  var initial$1 = initial.sections;
  var $$final$1 = $$final.sections;
  var removing = Id.$$Set.diff(Id.$$Set.fromArray(Id.$$Map.keysToArray(initial$1)), Id.$$Set.fromArray(Id.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear(state) {
  Id.$$Map.clear(state.sections);
}

var Sections_Model = T_Model;

var Sections = {
  Model: Sections_Model,
  Id: Id,
  key: key,
  map: map,
  equal: equal,
  all: all,
  get: get,
  update: update,
  updateMany: updateMany,
  remove: remove,
  getActions: getActions,
  forEach: forEach,
  clear: clear,
  isEmpty: isEmpty,
  isNotEmpty: isNotEmpty,
  getImages: getImages
};

function key$1(record) {
  return record.id;
}

function map$1(state) {
  return state.components;
}

function getImages$1(param) {
  return [];
}

var T_Model$1 = {
  clear: Component.clear,
  save: Component.save,
  $$delete: Component.$$delete,
  remove: Component.remove,
  IdComparator: Component.IdComparator,
  Id: Component.Id
};

var T_Id$1 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: ComponentId.make,
  $$null: ComponentId.$$null,
  zero: ComponentId.zero,
  $$Option: ComponentId.$$Option,
  Comparator: ComponentId.Comparator,
  Hasher: ComponentId.Hasher,
  $$Map: ComponentId.$$Map,
  $$Set: ComponentId.$$Set,
  MutableSet: ComponentId.MutableSet,
  Route: ComponentId.Route,
  $$Array: ComponentId.$$Array,
  SortArray: ComponentId.SortArray
};

var Id$1 = T_Id$1;

function all$1(state) {
  return Id$1.$$Map.valuesToArray(state.components);
}

function forEach$1(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$1(state), fn);
}

function isEmpty$1(state) {
  return Id$1.$$Map.isEmpty(state.components);
}

function isNotEmpty$1(state) {
  return !isEmpty$1(state);
}

function update$1(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.components;
  var existing = Id$1.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$1.$$Map.set(collection, item.id, item);
  }
}

function updateMany$1(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$1(state, extra);
        }));
  return state;
}

function remove$1(state, item) {
  Id$1.$$Map.remove(state.components, item.id);
}

function get$1(state, key) {
  var collection = state.components;
  return Id$1.$$Map.get(collection, key);
}

function getActions$1(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.components;
  var $$final$1 = $$final.components;
  var removing = Id$1.$$Set.diff(Id$1.$$Set.fromArray(Id$1.$$Map.keysToArray(initial$1)), Id$1.$$Set.fromArray(Id$1.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$1.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$1.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$1.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$1(state) {
  Id$1.$$Map.clear(state.components);
}

var Components_Model = T_Model$1;

var Components = {
  Model: Components_Model,
  Id: Id$1,
  key: key$1,
  map: map$1,
  equal: undefined,
  all: all$1,
  get: get$1,
  update: update$1,
  updateMany: updateMany$1,
  remove: remove$1,
  getActions: getActions$1,
  forEach: forEach$1,
  clear: clear$1,
  isEmpty: isEmpty$1,
  isNotEmpty: isNotEmpty$1,
  getImages: getImages$1
};

function key$2(record) {
  return record.id;
}

function map$2(state) {
  return state.procedures;
}

function getImages$2(state) {
  return Procedure.getImages(ProcedureId.$$Map.valuesToArray(state.procedures));
}

var T_Model$2 = {
  clear: Procedure.clear,
  save: Procedure.save,
  $$delete: Procedure.$$delete,
  remove: Procedure.remove,
  IdComparator: Procedure.IdComparator,
  Id: Procedure.Id
};

var T_Id$2 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: ProcedureId.make,
  $$null: ProcedureId.$$null,
  zero: ProcedureId.zero,
  $$Option: ProcedureId.$$Option,
  Comparator: ProcedureId.Comparator,
  Hasher: ProcedureId.Hasher,
  $$Map: ProcedureId.$$Map,
  $$Set: ProcedureId.$$Set,
  MutableSet: ProcedureId.MutableSet,
  Route: ProcedureId.Route,
  $$Array: ProcedureId.$$Array,
  SortArray: ProcedureId.SortArray
};

var Id$2 = T_Id$2;

function all$2(state) {
  return Id$2.$$Map.valuesToArray(state.procedures);
}

function forEach$2(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$2(state), fn);
}

function isEmpty$2(state) {
  return Id$2.$$Map.isEmpty(state.procedures);
}

function isNotEmpty$2(state) {
  return !isEmpty$2(state);
}

function update$2(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.procedures;
  var existing = Id$2.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$2.$$Map.set(collection, item.id, item);
  }
}

function updateMany$2(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$2(state, extra);
        }));
  return state;
}

function remove$2(state, item) {
  Id$2.$$Map.remove(state.procedures, item.id);
}

function get$2(state, key) {
  var collection = state.procedures;
  return Id$2.$$Map.get(collection, key);
}

function getActions$2(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.procedures;
  var $$final$1 = $$final.procedures;
  var removing = Id$2.$$Set.diff(Id$2.$$Set.fromArray(Id$2.$$Map.keysToArray(initial$1)), Id$2.$$Set.fromArray(Id$2.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$2.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$2.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$2.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$2(state) {
  Id$2.$$Map.clear(state.procedures);
}

var Procedures_Model = T_Model$2;

var Procedures = {
  Model: Procedures_Model,
  Id: Id$2,
  key: key$2,
  map: map$2,
  equal: undefined,
  all: all$2,
  get: get$2,
  update: update$2,
  updateMany: updateMany$2,
  remove: remove$2,
  getActions: getActions$2,
  forEach: forEach$2,
  clear: clear$2,
  isEmpty: isEmpty$2,
  isNotEmpty: isNotEmpty$2,
  getImages: getImages$2
};

function key$3(record) {
  return record.id;
}

function map$3(state) {
  return state.plannedProcedures;
}

function getImages$3(state) {
  return PlannedProcedure.getImages(ProcedureId.$$Map.valuesToArray(state.plannedProcedures));
}

var T_Model$3 = {
  clear: PlannedProcedure.clear,
  save: PlannedProcedure.save,
  $$delete: PlannedProcedure.$$delete,
  remove: PlannedProcedure.remove,
  IdComparator: PlannedProcedure.IdComparator,
  Id: PlannedProcedure.Id
};

var T_Id$3 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: ProcedureId.make,
  $$null: ProcedureId.$$null,
  zero: ProcedureId.zero,
  $$Option: ProcedureId.$$Option,
  Comparator: ProcedureId.Comparator,
  Hasher: ProcedureId.Hasher,
  $$Map: ProcedureId.$$Map,
  $$Set: ProcedureId.$$Set,
  MutableSet: ProcedureId.MutableSet,
  Route: ProcedureId.Route,
  $$Array: ProcedureId.$$Array,
  SortArray: ProcedureId.SortArray
};

var Id$3 = T_Id$3;

function all$3(state) {
  return Id$3.$$Map.valuesToArray(state.plannedProcedures);
}

function forEach$3(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$3(state), fn);
}

function isEmpty$3(state) {
  return Id$3.$$Map.isEmpty(state.plannedProcedures);
}

function isNotEmpty$3(state) {
  return !isEmpty$3(state);
}

function update$3(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.plannedProcedures;
  var existing = Id$3.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$3.$$Map.set(collection, item.id, item);
  }
}

function updateMany$3(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$3(state, extra);
        }));
  return state;
}

function remove$3(state, item) {
  Id$3.$$Map.remove(state.plannedProcedures, item.id);
}

function get$3(state, key) {
  var collection = state.plannedProcedures;
  return Id$3.$$Map.get(collection, key);
}

function getActions$3(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.plannedProcedures;
  var $$final$1 = $$final.plannedProcedures;
  var removing = Id$3.$$Set.diff(Id$3.$$Set.fromArray(Id$3.$$Map.keysToArray(initial$1)), Id$3.$$Set.fromArray(Id$3.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$3.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$3.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$3.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$3(state) {
  Id$3.$$Map.clear(state.plannedProcedures);
}

var PlannedProcedures_Model = T_Model$3;

var PlannedProcedures = {
  Model: PlannedProcedures_Model,
  Id: Id$3,
  key: key$3,
  map: map$3,
  equal: undefined,
  all: all$3,
  get: get$3,
  update: update$3,
  updateMany: updateMany$3,
  remove: remove$3,
  getActions: getActions$3,
  forEach: forEach$3,
  clear: clear$3,
  isEmpty: isEmpty$3,
  isNotEmpty: isNotEmpty$3,
  getImages: getImages$3
};

function key$4(record) {
  return record.id;
}

function map$4(state) {
  return state.inspections;
}

var equal$1 = (function (i1, i2) {
    return Caml_obj.equal(i1.id, i2.id);
  });

function getImages$4(state) {
  return Inspection.getImages(InspectionId.$$Map.valuesToArray(state.inspections));
}

var T_Model$4 = {
  clear: Inspection.clear,
  save: Inspection.save,
  $$delete: Inspection.$$delete,
  remove: Inspection.remove,
  IdComparator: Inspection.IdComparator,
  Id: Inspection.Id
};

var T_Id$4 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: InspectionId.make,
  $$null: InspectionId.$$null,
  zero: InspectionId.zero,
  $$Option: InspectionId.$$Option,
  Comparator: InspectionId.Comparator,
  Hasher: InspectionId.Hasher,
  $$Map: InspectionId.$$Map,
  $$Set: InspectionId.$$Set,
  MutableSet: InspectionId.MutableSet,
  Route: InspectionId.Route,
  $$Array: InspectionId.$$Array,
  SortArray: InspectionId.SortArray
};

var Id$4 = T_Id$4;

function all$4(state) {
  return Id$4.$$Map.valuesToArray(state.inspections);
}

function forEach$4(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$4(state), fn);
}

function isEmpty$4(state) {
  return Id$4.$$Map.isEmpty(state.inspections);
}

function isNotEmpty$4(state) {
  return !isEmpty$4(state);
}

function update$4(state, item) {
  var eq = Prelude.default(equal$1, Caml_obj.equal);
  var collection = state.inspections;
  var existing = Id$4.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$4.$$Map.set(collection, item.id, item);
  }
}

function updateMany$4(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$4(state, extra);
        }));
  return state;
}

function remove$4(state, item) {
  Id$4.$$Map.remove(state.inspections, item.id);
}

function get$4(state, key) {
  var collection = state.inspections;
  return Id$4.$$Map.get(collection, key);
}

function getActions$4(initial, $$final) {
  var eq = Prelude.default(equal$1, Caml_obj.equal);
  var initial$1 = initial.inspections;
  var $$final$1 = $$final.inspections;
  var removing = Id$4.$$Set.diff(Id$4.$$Set.fromArray(Id$4.$$Map.keysToArray(initial$1)), Id$4.$$Set.fromArray(Id$4.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$4.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$4.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$4.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$4(state) {
  Id$4.$$Map.clear(state.inspections);
}

var Inspections_Model = T_Model$4;

var Inspections = {
  Model: Inspections_Model,
  Id: Id$4,
  key: key$4,
  map: map$4,
  equal: equal$1,
  all: all$4,
  get: get$4,
  update: update$4,
  updateMany: updateMany$4,
  remove: remove$4,
  getActions: getActions$4,
  forEach: forEach$4,
  clear: clear$4,
  isEmpty: isEmpty$4,
  isNotEmpty: isNotEmpty$4,
  getImages: getImages$4
};

function key$5(record) {
  return record.id;
}

function map$5(state) {
  return state.newInspections;
}

function getImages$5(state) {
  return NewInspection.getImages(InspectionId.$$Map.valuesToArray(state.newInspections));
}

var T_Model$5 = {
  clear: NewInspection.clear,
  save: NewInspection.save,
  $$delete: NewInspection.$$delete,
  remove: NewInspection.remove,
  IdComparator: NewInspection.IdComparator,
  Id: NewInspection.Id
};

var T_Id$5 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: InspectionId.make,
  $$null: InspectionId.$$null,
  zero: InspectionId.zero,
  $$Option: InspectionId.$$Option,
  Comparator: InspectionId.Comparator,
  Hasher: InspectionId.Hasher,
  $$Map: InspectionId.$$Map,
  $$Set: InspectionId.$$Set,
  MutableSet: InspectionId.MutableSet,
  Route: InspectionId.Route,
  $$Array: InspectionId.$$Array,
  SortArray: InspectionId.SortArray
};

var Id$5 = T_Id$5;

function all$5(state) {
  return Id$5.$$Map.valuesToArray(state.newInspections);
}

function forEach$5(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$5(state), fn);
}

function isEmpty$5(state) {
  return Id$5.$$Map.isEmpty(state.newInspections);
}

function isNotEmpty$5(state) {
  return !isEmpty$5(state);
}

function update$5(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.newInspections;
  var existing = Id$5.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$5.$$Map.set(collection, item.id, item);
  }
}

function updateMany$5(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$5(state, extra);
        }));
  return state;
}

function remove$5(state, item) {
  Id$5.$$Map.remove(state.newInspections, item.id);
}

function get$5(state, key) {
  var collection = state.newInspections;
  return Id$5.$$Map.get(collection, key);
}

function getActions$5(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.newInspections;
  var $$final$1 = $$final.newInspections;
  var removing = Id$5.$$Set.diff(Id$5.$$Set.fromArray(Id$5.$$Map.keysToArray(initial$1)), Id$5.$$Set.fromArray(Id$5.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$5.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$5.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$5.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$5(state) {
  Id$5.$$Map.clear(state.newInspections);
}

var NewInspections_Model = T_Model$5;

var NewInspections = {
  Model: NewInspections_Model,
  Id: Id$5,
  key: key$5,
  map: map$5,
  equal: undefined,
  all: all$5,
  get: get$5,
  update: update$5,
  updateMany: updateMany$5,
  remove: remove$5,
  getActions: getActions$5,
  forEach: forEach$5,
  clear: clear$5,
  isEmpty: isEmpty$5,
  isNotEmpty: isNotEmpty$5,
  getImages: getImages$5
};

function key$6(record) {
  return record.id;
}

function map$6(state) {
  return state.draftInspections;
}

function getImages$6(state) {
  return DraftInspection.getImages(InspectionId.$$Map.valuesToArray(state.draftInspections));
}

var T_Model$6 = {
  clear: DraftInspection.clear,
  save: DraftInspection.save,
  $$delete: DraftInspection.$$delete,
  remove: DraftInspection.remove,
  IdComparator: DraftInspection.IdComparator,
  Id: DraftInspection.Id
};

var T_Id$6 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: InspectionId.make,
  $$null: InspectionId.$$null,
  zero: InspectionId.zero,
  $$Option: InspectionId.$$Option,
  Comparator: InspectionId.Comparator,
  Hasher: InspectionId.Hasher,
  $$Map: InspectionId.$$Map,
  $$Set: InspectionId.$$Set,
  MutableSet: InspectionId.MutableSet,
  Route: InspectionId.Route,
  $$Array: InspectionId.$$Array,
  SortArray: InspectionId.SortArray
};

var Id$6 = T_Id$6;

function all$6(state) {
  return Id$6.$$Map.valuesToArray(state.draftInspections);
}

function forEach$6(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$6(state), fn);
}

function isEmpty$6(state) {
  return Id$6.$$Map.isEmpty(state.draftInspections);
}

function isNotEmpty$6(state) {
  return !isEmpty$6(state);
}

function update$6(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.draftInspections;
  var existing = Id$6.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$6.$$Map.set(collection, item.id, item);
  }
}

function updateMany$6(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$6(state, extra);
        }));
  return state;
}

function remove$6(state, item) {
  Id$6.$$Map.remove(state.draftInspections, item.id);
}

function get$6(state, key) {
  var collection = state.draftInspections;
  return Id$6.$$Map.get(collection, key);
}

function getActions$6(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.draftInspections;
  var $$final$1 = $$final.draftInspections;
  var removing = Id$6.$$Set.diff(Id$6.$$Set.fromArray(Id$6.$$Map.keysToArray(initial$1)), Id$6.$$Set.fromArray(Id$6.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$6.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$6.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$6.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$6(state) {
  Id$6.$$Map.clear(state.draftInspections);
}

var DraftInspections_Model = T_Model$6;

var DraftInspections = {
  Model: DraftInspections_Model,
  Id: Id$6,
  key: key$6,
  map: map$6,
  equal: undefined,
  all: all$6,
  get: get$6,
  update: update$6,
  updateMany: updateMany$6,
  remove: remove$6,
  getActions: getActions$6,
  forEach: forEach$6,
  clear: clear$6,
  isEmpty: isEmpty$6,
  isNotEmpty: isNotEmpty$6,
  getImages: getImages$6
};

function key$7(record) {
  return record.id;
}

function map$7(state) {
  return state.deficiencies;
}

function getImages$7(param) {
  return [];
}

var T_Model$7 = {
  clear: Deficiency.clear,
  save: Deficiency.save,
  $$delete: Deficiency.$$delete,
  remove: Deficiency.remove,
  IdComparator: Deficiency.IdComparator,
  Id: Deficiency.Id
};

var T_Id$7 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: DeficiencyId.make,
  $$null: DeficiencyId.$$null,
  zero: DeficiencyId.zero,
  $$Option: DeficiencyId.$$Option,
  Comparator: DeficiencyId.Comparator,
  Hasher: DeficiencyId.Hasher,
  $$Map: DeficiencyId.$$Map,
  $$Set: DeficiencyId.$$Set,
  MutableSet: DeficiencyId.MutableSet,
  Route: DeficiencyId.Route,
  $$Array: DeficiencyId.$$Array,
  SortArray: DeficiencyId.SortArray
};

var Id$7 = T_Id$7;

function all$7(state) {
  return Id$7.$$Map.valuesToArray(state.deficiencies);
}

function forEach$7(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$7(state), fn);
}

function isEmpty$7(state) {
  return Id$7.$$Map.isEmpty(state.deficiencies);
}

function isNotEmpty$7(state) {
  return !isEmpty$7(state);
}

function update$7(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.deficiencies;
  var existing = Id$7.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$7.$$Map.set(collection, item.id, item);
  }
}

function updateMany$7(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$7(state, extra);
        }));
  return state;
}

function remove$7(state, item) {
  Id$7.$$Map.remove(state.deficiencies, item.id);
}

function get$7(state, key) {
  var collection = state.deficiencies;
  return Id$7.$$Map.get(collection, key);
}

function getActions$7(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.deficiencies;
  var $$final$1 = $$final.deficiencies;
  var removing = Id$7.$$Set.diff(Id$7.$$Set.fromArray(Id$7.$$Map.keysToArray(initial$1)), Id$7.$$Set.fromArray(Id$7.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$7.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$7.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$7.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$7(state) {
  Id$7.$$Map.clear(state.deficiencies);
}

var Deficiencies_Model = T_Model$7;

var Deficiencies = {
  Model: Deficiencies_Model,
  Id: Id$7,
  key: key$7,
  map: map$7,
  equal: undefined,
  all: all$7,
  get: get$7,
  update: update$7,
  updateMany: updateMany$7,
  remove: remove$7,
  getActions: getActions$7,
  forEach: forEach$7,
  clear: clear$7,
  isEmpty: isEmpty$7,
  isNotEmpty: isNotEmpty$7,
  getImages: getImages$7
};

function key$8(record) {
  return record.id;
}

function map$8(state) {
  return state.ratingStylingRules;
}

function getImages$8(param) {
  return [];
}

var $$let = Rating.StylingRule.Identifier;

var T_Model$8 = {
  clear: Rating.StylingRule.clear,
  save: Rating.StylingRule.save,
  $$delete: Rating.StylingRule.$$delete,
  remove: Rating.StylingRule.remove,
  IdComparator: Rating.StylingRule.IdComparator,
  Id: Rating.StylingRule.Id
};

var T_Id$8 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: $$let.make,
  $$null: $$let.$$null,
  zero: $$let.zero,
  $$Option: $$let.$$Option,
  Comparator: $$let.Comparator,
  Hasher: $$let.Hasher,
  $$Map: $$let.$$Map,
  $$Set: $$let.$$Set,
  MutableSet: $$let.MutableSet,
  Route: $$let.Route,
  $$Array: $$let.$$Array,
  SortArray: $$let.SortArray
};

var Id$8 = T_Id$8;

function all$8(state) {
  return Id$8.$$Map.valuesToArray(state.ratingStylingRules);
}

function forEach$8(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$8(state), fn);
}

function isEmpty$8(state) {
  return Id$8.$$Map.isEmpty(state.ratingStylingRules);
}

function isNotEmpty$8(state) {
  return !isEmpty$8(state);
}

function update$8(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.ratingStylingRules;
  var existing = Id$8.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$8.$$Map.set(collection, item.id, item);
  }
}

function updateMany$8(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$8(state, extra);
        }));
  return state;
}

function remove$8(state, item) {
  Id$8.$$Map.remove(state.ratingStylingRules, item.id);
}

function get$8(state, key) {
  var collection = state.ratingStylingRules;
  return Id$8.$$Map.get(collection, key);
}

function getActions$8(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.ratingStylingRules;
  var $$final$1 = $$final.ratingStylingRules;
  var removing = Id$8.$$Set.diff(Id$8.$$Set.fromArray(Id$8.$$Map.keysToArray(initial$1)), Id$8.$$Set.fromArray(Id$8.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$8.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$8.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$8.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$8(state) {
  Id$8.$$Map.clear(state.ratingStylingRules);
}

var RatingRules_Model = T_Model$8;

var RatingRules = {
  Model: RatingRules_Model,
  Id: Id$8,
  key: key$8,
  map: map$8,
  equal: undefined,
  all: all$8,
  get: get$8,
  update: update$8,
  updateMany: updateMany$8,
  remove: remove$8,
  getActions: getActions$8,
  forEach: forEach$8,
  clear: clear$8,
  isEmpty: isEmpty$8,
  isNotEmpty: isNotEmpty$8,
  getImages: getImages$8
};

function key$9(record) {
  return record.id;
}

function map$9(state) {
  return state.newComments;
}

function getImages$9(param) {
  return [];
}

var T_Model$9 = {
  clear: NewComment.clear,
  save: NewComment.save,
  $$delete: NewComment.$$delete,
  remove: NewComment.remove,
  IdComparator: NewComment.IdComparator,
  Id: NewComment.Id
};

var T_Id$9 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: $$Comment.Identifier.make,
  $$null: $$Comment.Identifier.$$null,
  zero: $$Comment.Identifier.zero,
  $$Option: $$Comment.Identifier.$$Option,
  Comparator: $$Comment.Identifier.Comparator,
  Hasher: $$Comment.Identifier.Hasher,
  $$Map: $$Comment.Identifier.$$Map,
  $$Set: $$Comment.Identifier.$$Set,
  MutableSet: $$Comment.Identifier.MutableSet,
  Route: $$Comment.Identifier.Route,
  $$Array: $$Comment.Identifier.$$Array,
  SortArray: $$Comment.Identifier.SortArray
};

var Id$9 = T_Id$9;

function all$9(state) {
  return Id$9.$$Map.valuesToArray(state.newComments);
}

function forEach$9(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$9(state), fn);
}

function isEmpty$9(state) {
  return Id$9.$$Map.isEmpty(state.newComments);
}

function isNotEmpty$9(state) {
  return !isEmpty$9(state);
}

function update$9(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.newComments;
  var existing = Id$9.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$9.$$Map.set(collection, item.id, item);
  }
}

function updateMany$9(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$9(state, extra);
        }));
  return state;
}

function remove$9(state, item) {
  Id$9.$$Map.remove(state.newComments, item.id);
}

function get$9(state, key) {
  var collection = state.newComments;
  return Id$9.$$Map.get(collection, key);
}

function getActions$9(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.newComments;
  var $$final$1 = $$final.newComments;
  var removing = Id$9.$$Set.diff(Id$9.$$Set.fromArray(Id$9.$$Map.keysToArray(initial$1)), Id$9.$$Set.fromArray(Id$9.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$9.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$9.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$9.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$9(state) {
  Id$9.$$Map.clear(state.newComments);
}

var Comments_Model = T_Model$9;

var Comments = {
  Model: Comments_Model,
  Id: Id$9,
  key: key$9,
  map: map$9,
  equal: undefined,
  all: all$9,
  get: get$9,
  update: update$9,
  updateMany: updateMany$9,
  remove: remove$9,
  getActions: getActions$9,
  forEach: forEach$9,
  clear: clear$9,
  isEmpty: isEmpty$9,
  isNotEmpty: isNotEmpty$9,
  getImages: getImages$9
};

function key$10(record) {
  return record.id;
}

function map$10(state) {
  return state.updatedComments;
}

function getImages$10(param) {
  return [];
}

var T_Model$10 = {
  clear: UpdatedComment.clear,
  save: UpdatedComment.save,
  $$delete: UpdatedComment.$$delete,
  remove: UpdatedComment.remove,
  IdComparator: UpdatedComment.IdComparator,
  Id: UpdatedComment.Id
};

var T_Id$10 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: $$Comment.Identifier.make,
  $$null: $$Comment.Identifier.$$null,
  zero: $$Comment.Identifier.zero,
  $$Option: $$Comment.Identifier.$$Option,
  Comparator: $$Comment.Identifier.Comparator,
  Hasher: $$Comment.Identifier.Hasher,
  $$Map: $$Comment.Identifier.$$Map,
  $$Set: $$Comment.Identifier.$$Set,
  MutableSet: $$Comment.Identifier.MutableSet,
  Route: $$Comment.Identifier.Route,
  $$Array: $$Comment.Identifier.$$Array,
  SortArray: $$Comment.Identifier.SortArray
};

var Id$10 = T_Id$10;

function all$10(state) {
  return Id$10.$$Map.valuesToArray(state.updatedComments);
}

function forEach$10(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$10(state), fn);
}

function isEmpty$10(state) {
  return Id$10.$$Map.isEmpty(state.updatedComments);
}

function isNotEmpty$10(state) {
  return !isEmpty$10(state);
}

function update$10(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.updatedComments;
  var existing = Id$10.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$10.$$Map.set(collection, item.id, item);
  }
}

function updateMany$10(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$10(state, extra);
        }));
  return state;
}

function remove$10(state, item) {
  Id$10.$$Map.remove(state.updatedComments, item.id);
}

function get$10(state, key) {
  var collection = state.updatedComments;
  return Id$10.$$Map.get(collection, key);
}

function getActions$10(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.updatedComments;
  var $$final$1 = $$final.updatedComments;
  var removing = Id$10.$$Set.diff(Id$10.$$Set.fromArray(Id$10.$$Map.keysToArray(initial$1)), Id$10.$$Set.fromArray(Id$10.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$10.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$10.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$10.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$10(state) {
  Id$10.$$Map.clear(state.updatedComments);
}

var UpdatedComments_Model = T_Model$10;

var UpdatedComments = {
  Model: UpdatedComments_Model,
  Id: Id$10,
  key: key$10,
  map: map$10,
  equal: undefined,
  all: all$10,
  get: get$10,
  update: update$10,
  updateMany: updateMany$10,
  remove: remove$10,
  getActions: getActions$10,
  forEach: forEach$10,
  clear: clear$10,
  isEmpty: isEmpty$10,
  isNotEmpty: isNotEmpty$10,
  getImages: getImages$10
};

function key$11(record) {
  return record.id;
}

function map$11(state) {
  return state.userProfiles;
}

function getImages$11(state) {
  return UserProfile.getImages(ProfileId.$$Map.valuesToArray(state.userProfiles));
}

var T_Model$11 = {
  clear: UserProfile.clear,
  save: UserProfile.save,
  $$delete: UserProfile.$$delete,
  remove: UserProfile.remove,
  IdComparator: UserProfile.IdComparator,
  Id: UserProfile.Id
};

var T_Id$11 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: ProfileId.make,
  $$null: ProfileId.$$null,
  zero: ProfileId.zero,
  $$Option: ProfileId.$$Option,
  Comparator: ProfileId.Comparator,
  Hasher: ProfileId.Hasher,
  $$Map: ProfileId.$$Map,
  $$Set: ProfileId.$$Set,
  MutableSet: ProfileId.MutableSet,
  Route: ProfileId.Route,
  $$Array: ProfileId.$$Array,
  SortArray: ProfileId.SortArray
};

var Id$11 = T_Id$11;

function all$11(state) {
  return Id$11.$$Map.valuesToArray(state.userProfiles);
}

function forEach$11(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$11(state), fn);
}

function isEmpty$11(state) {
  return Id$11.$$Map.isEmpty(state.userProfiles);
}

function isNotEmpty$11(state) {
  return !isEmpty$11(state);
}

function update$11(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.userProfiles;
  var existing = Id$11.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$11.$$Map.set(collection, item.id, item);
  }
}

function updateMany$11(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$11(state, extra);
        }));
  return state;
}

function remove$11(state, item) {
  Id$11.$$Map.remove(state.userProfiles, item.id);
}

function get$11(state, key) {
  var collection = state.userProfiles;
  return Id$11.$$Map.get(collection, key);
}

function getActions$11(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.userProfiles;
  var $$final$1 = $$final.userProfiles;
  var removing = Id$11.$$Set.diff(Id$11.$$Set.fromArray(Id$11.$$Map.keysToArray(initial$1)), Id$11.$$Set.fromArray(Id$11.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$11.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$11.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$11.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$11(state) {
  Id$11.$$Map.clear(state.userProfiles);
}

var UserProfiles_Model = T_Model$11;

var UserProfiles = {
  Model: UserProfiles_Model,
  Id: Id$11,
  key: key$11,
  map: map$11,
  equal: undefined,
  all: all$11,
  get: get$11,
  update: update$11,
  updateMany: updateMany$11,
  remove: remove$11,
  getActions: getActions$11,
  forEach: forEach$11,
  clear: clear$11,
  isEmpty: isEmpty$11,
  isNotEmpty: isNotEmpty$11,
  getImages: getImages$11
};

function key$12(record) {
  return record.id;
}

function map$12(state) {
  return state.staffers;
}

function getImages$12(param) {
  return [];
}

var T_Model$12 = {
  clear: VesselStaff.clear,
  save: VesselStaff.save,
  $$delete: VesselStaff.$$delete,
  remove: VesselStaff.remove,
  IdComparator: VesselStaff.IdComparator,
  Id: VesselStaff.Id
};

var T_Id$12 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: VesselStaff.Identifier.make,
  $$null: VesselStaff.Identifier.$$null,
  zero: VesselStaff.Identifier.zero,
  $$Option: VesselStaff.Identifier.$$Option,
  Comparator: VesselStaff.Identifier.Comparator,
  Hasher: VesselStaff.Identifier.Hasher,
  $$Map: VesselStaff.Identifier.$$Map,
  $$Set: VesselStaff.Identifier.$$Set,
  MutableSet: VesselStaff.Identifier.MutableSet,
  Route: VesselStaff.Identifier.Route,
  $$Array: VesselStaff.Identifier.$$Array,
  SortArray: VesselStaff.Identifier.SortArray
};

var Id$12 = T_Id$12;

function all$12(state) {
  return Id$12.$$Map.valuesToArray(state.staffers);
}

function forEach$12(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$12(state), fn);
}

function isEmpty$12(state) {
  return Id$12.$$Map.isEmpty(state.staffers);
}

function isNotEmpty$12(state) {
  return !isEmpty$12(state);
}

function update$12(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.staffers;
  var existing = Id$12.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$12.$$Map.set(collection, item.id, item);
  }
}

function updateMany$12(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$12(state, extra);
        }));
  return state;
}

function remove$12(state, item) {
  Id$12.$$Map.remove(state.staffers, item.id);
}

function get$12(state, key) {
  var collection = state.staffers;
  return Id$12.$$Map.get(collection, key);
}

function getActions$12(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.staffers;
  var $$final$1 = $$final.staffers;
  var removing = Id$12.$$Set.diff(Id$12.$$Set.fromArray(Id$12.$$Map.keysToArray(initial$1)), Id$12.$$Set.fromArray(Id$12.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$12.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$12.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$12.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$12(state) {
  Id$12.$$Map.clear(state.staffers);
}

var Staffers_Model = T_Model$12;

var Staffers = {
  Model: Staffers_Model,
  Id: Id$12,
  key: key$12,
  map: map$12,
  equal: undefined,
  all: all$12,
  get: get$12,
  update: update$12,
  updateMany: updateMany$12,
  remove: remove$12,
  getActions: getActions$12,
  forEach: forEach$12,
  clear: clear$12,
  isEmpty: isEmpty$12,
  isNotEmpty: isNotEmpty$12,
  getImages: getImages$12
};

function key$13(record) {
  return record.id;
}

function map$13(state) {
  return state.r4schecklists;
}

function getImages$13(param) {
  return [];
}

var T_Model$13 = {
  clear: R4SChecklist.clear,
  save: R4SChecklist.save,
  $$delete: R4SChecklist.$$delete,
  remove: R4SChecklist.remove,
  IdComparator: R4SChecklist.IdComparator,
  Id: R4SChecklist.Id
};

var T_Id$13 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: R4SChecklistId.make,
  $$null: R4SChecklistId.$$null,
  zero: R4SChecklistId.zero,
  $$Option: R4SChecklistId.$$Option,
  Comparator: R4SChecklistId.Comparator,
  Hasher: R4SChecklistId.Hasher,
  $$Map: R4SChecklistId.$$Map,
  $$Set: R4SChecklistId.$$Set,
  MutableSet: R4SChecklistId.MutableSet,
  Route: R4SChecklistId.Route,
  $$Array: R4SChecklistId.$$Array,
  SortArray: R4SChecklistId.SortArray
};

var Id$13 = T_Id$13;

function all$13(state) {
  return Id$13.$$Map.valuesToArray(state.r4schecklists);
}

function forEach$13(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$13(state), fn);
}

function isEmpty$13(state) {
  return Id$13.$$Map.isEmpty(state.r4schecklists);
}

function isNotEmpty$13(state) {
  return !isEmpty$13(state);
}

function update$13(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.r4schecklists;
  var existing = Id$13.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$13.$$Map.set(collection, item.id, item);
  }
}

function updateMany$13(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$13(state, extra);
        }));
  return state;
}

function remove$13(state, item) {
  Id$13.$$Map.remove(state.r4schecklists, item.id);
}

function get$13(state, key) {
  var collection = state.r4schecklists;
  return Id$13.$$Map.get(collection, key);
}

function getActions$13(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.r4schecklists;
  var $$final$1 = $$final.r4schecklists;
  var removing = Id$13.$$Set.diff(Id$13.$$Set.fromArray(Id$13.$$Map.keysToArray(initial$1)), Id$13.$$Set.fromArray(Id$13.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$13.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$13.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$13.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$13(state) {
  Id$13.$$Map.clear(state.r4schecklists);
}

var R4SChecklist_Model = T_Model$13;

var R4SChecklist$1 = {
  Model: R4SChecklist_Model,
  Id: Id$13,
  key: key$13,
  map: map$13,
  equal: undefined,
  all: all$13,
  get: get$13,
  update: update$13,
  updateMany: updateMany$13,
  remove: remove$13,
  getActions: getActions$13,
  forEach: forEach$13,
  clear: clear$13,
  isEmpty: isEmpty$13,
  isNotEmpty: isNotEmpty$13,
  getImages: getImages$13
};

function key$14(record) {
  return record.id;
}

function map$14(state) {
  return state.companyForms;
}

function getImages$14(param) {
  return [];
}

var T_Model$14 = {
  clear: CompanyForm.clear,
  save: CompanyForm.save,
  $$delete: CompanyForm.$$delete,
  remove: CompanyForm.remove,
  IdComparator: CompanyForm.IdComparator,
  Id: CompanyForm.Id
};

var T_Id$14 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: CompanyFormId.make,
  $$null: CompanyFormId.$$null,
  zero: CompanyFormId.zero,
  $$Option: CompanyFormId.$$Option,
  Comparator: CompanyFormId.Comparator,
  Hasher: CompanyFormId.Hasher,
  $$Map: CompanyFormId.$$Map,
  $$Set: CompanyFormId.$$Set,
  MutableSet: CompanyFormId.MutableSet,
  Route: CompanyFormId.Route,
  $$Array: CompanyFormId.$$Array,
  SortArray: CompanyFormId.SortArray
};

var Id$14 = T_Id$14;

function all$14(state) {
  return Id$14.$$Map.valuesToArray(state.companyForms);
}

function forEach$14(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$14(state), fn);
}

function isEmpty$14(state) {
  return Id$14.$$Map.isEmpty(state.companyForms);
}

function isNotEmpty$14(state) {
  return !isEmpty$14(state);
}

function update$14(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.companyForms;
  var existing = Id$14.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$14.$$Map.set(collection, item.id, item);
  }
}

function updateMany$14(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$14(state, extra);
        }));
  return state;
}

function remove$14(state, item) {
  Id$14.$$Map.remove(state.companyForms, item.id);
}

function get$14(state, key) {
  var collection = state.companyForms;
  return Id$14.$$Map.get(collection, key);
}

function getActions$14(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.companyForms;
  var $$final$1 = $$final.companyForms;
  var removing = Id$14.$$Set.diff(Id$14.$$Set.fromArray(Id$14.$$Map.keysToArray(initial$1)), Id$14.$$Set.fromArray(Id$14.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$14.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$14.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$14.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$14(state) {
  Id$14.$$Map.clear(state.companyForms);
}

var CompanyForms_Model = T_Model$14;

var CompanyForms = {
  Model: CompanyForms_Model,
  Id: Id$14,
  key: key$14,
  map: map$14,
  equal: undefined,
  all: all$14,
  get: get$14,
  update: update$14,
  updateMany: updateMany$14,
  remove: remove$14,
  getActions: getActions$14,
  forEach: forEach$14,
  clear: clear$14,
  isEmpty: isEmpty$14,
  isNotEmpty: isNotEmpty$14,
  getImages: getImages$14
};

function key$15(record) {
  return record.id;
}

function map$15(state) {
  return state.companyFormSubmissions;
}

function getImages$15(param) {
  return [];
}

var T_Model$15 = {
  clear: CompanyFormSubmission.clear,
  save: CompanyFormSubmission.save,
  $$delete: CompanyFormSubmission.$$delete,
  remove: CompanyFormSubmission.remove,
  IdComparator: CompanyFormSubmission.IdComparator,
  Id: CompanyFormSubmission.Id
};

var T_Id$15 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: CompanyFormSubmissionId.make,
  $$null: CompanyFormSubmissionId.$$null,
  zero: CompanyFormSubmissionId.zero,
  $$Option: CompanyFormSubmissionId.$$Option,
  Comparator: CompanyFormSubmissionId.Comparator,
  Hasher: CompanyFormSubmissionId.Hasher,
  $$Map: CompanyFormSubmissionId.$$Map,
  $$Set: CompanyFormSubmissionId.$$Set,
  MutableSet: CompanyFormSubmissionId.MutableSet,
  Route: CompanyFormSubmissionId.Route,
  $$Array: CompanyFormSubmissionId.$$Array,
  SortArray: CompanyFormSubmissionId.SortArray
};

var Id$15 = T_Id$15;

function all$15(state) {
  return Id$15.$$Map.valuesToArray(state.companyFormSubmissions);
}

function forEach$15(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$15(state), fn);
}

function isEmpty$15(state) {
  return Id$15.$$Map.isEmpty(state.companyFormSubmissions);
}

function isNotEmpty$15(state) {
  return !isEmpty$15(state);
}

function update$15(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.companyFormSubmissions;
  var existing = Id$15.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$15.$$Map.set(collection, item.id, item);
  }
}

function updateMany$15(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$15(state, extra);
        }));
  return state;
}

function remove$15(state, item) {
  Id$15.$$Map.remove(state.companyFormSubmissions, item.id);
}

function get$15(state, key) {
  var collection = state.companyFormSubmissions;
  return Id$15.$$Map.get(collection, key);
}

function getActions$15(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.companyFormSubmissions;
  var $$final$1 = $$final.companyFormSubmissions;
  var removing = Id$15.$$Set.diff(Id$15.$$Set.fromArray(Id$15.$$Map.keysToArray(initial$1)), Id$15.$$Set.fromArray(Id$15.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$15.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$15.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$15.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$15(state) {
  Id$15.$$Map.clear(state.companyFormSubmissions);
}

var CompanyFormSubmissions_Model = T_Model$15;

var CompanyFormSubmissions = {
  Model: CompanyFormSubmissions_Model,
  Id: Id$15,
  key: key$15,
  map: map$15,
  equal: undefined,
  all: all$15,
  get: get$15,
  update: update$15,
  updateMany: updateMany$15,
  remove: remove$15,
  getActions: getActions$15,
  forEach: forEach$15,
  clear: clear$15,
  isEmpty: isEmpty$15,
  isNotEmpty: isNotEmpty$15,
  getImages: getImages$15
};

function key$16(record) {
  return record.id;
}

function map$16(state) {
  return state.newCompanyFormSubmissions;
}

function getImages$16(param) {
  return [];
}

var T_Model$16 = {
  clear: CompanyFormSubmission.clear,
  save: CompanyFormSubmission.save,
  $$delete: CompanyFormSubmission.$$delete,
  remove: CompanyFormSubmission.remove,
  IdComparator: CompanyFormSubmission.IdComparator,
  Id: CompanyFormSubmission.Id
};

var T_Id$16 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: CompanyFormSubmissionId.make,
  $$null: CompanyFormSubmissionId.$$null,
  zero: CompanyFormSubmissionId.zero,
  $$Option: CompanyFormSubmissionId.$$Option,
  Comparator: CompanyFormSubmissionId.Comparator,
  Hasher: CompanyFormSubmissionId.Hasher,
  $$Map: CompanyFormSubmissionId.$$Map,
  $$Set: CompanyFormSubmissionId.$$Set,
  MutableSet: CompanyFormSubmissionId.MutableSet,
  Route: CompanyFormSubmissionId.Route,
  $$Array: CompanyFormSubmissionId.$$Array,
  SortArray: CompanyFormSubmissionId.SortArray
};

var Id$16 = T_Id$16;

function all$16(state) {
  return Id$16.$$Map.valuesToArray(state.newCompanyFormSubmissions);
}

function forEach$16(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$16(state), fn);
}

function isEmpty$16(state) {
  return Id$16.$$Map.isEmpty(state.newCompanyFormSubmissions);
}

function isNotEmpty$16(state) {
  return !isEmpty$16(state);
}

function update$16(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.newCompanyFormSubmissions;
  var existing = Id$16.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$16.$$Map.set(collection, item.id, item);
  }
}

function updateMany$16(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$16(state, extra);
        }));
  return state;
}

function remove$16(state, item) {
  Id$16.$$Map.remove(state.newCompanyFormSubmissions, item.id);
}

function get$16(state, key) {
  var collection = state.newCompanyFormSubmissions;
  return Id$16.$$Map.get(collection, key);
}

function getActions$16(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.newCompanyFormSubmissions;
  var $$final$1 = $$final.newCompanyFormSubmissions;
  var removing = Id$16.$$Set.diff(Id$16.$$Set.fromArray(Id$16.$$Map.keysToArray(initial$1)), Id$16.$$Set.fromArray(Id$16.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$16.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$16.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$16.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$16(state) {
  Id$16.$$Map.clear(state.newCompanyFormSubmissions);
}

var NewCompanyFormSubmissions_Model = T_Model$16;

var NewCompanyFormSubmissions = {
  Model: NewCompanyFormSubmissions_Model,
  Id: Id$16,
  key: key$16,
  map: map$16,
  equal: undefined,
  all: all$16,
  get: get$16,
  update: update$16,
  updateMany: updateMany$16,
  remove: remove$16,
  getActions: getActions$16,
  forEach: forEach$16,
  clear: clear$16,
  isEmpty: isEmpty$16,
  isNotEmpty: isNotEmpty$16,
  getImages: getImages$16
};

function key$17(record) {
  return record.id;
}

function map$17(state) {
  return state.draftCompanyFormSubmissions;
}

function getImages$17(param) {
  return [];
}

var T_Model$17 = {
  clear: CompanyFormSubmission.clear,
  save: CompanyFormSubmission.save,
  $$delete: CompanyFormSubmission.$$delete,
  remove: CompanyFormSubmission.remove,
  IdComparator: CompanyFormSubmission.IdComparator,
  Id: CompanyFormSubmission.Id
};

var T_Id$17 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: CompanyFormSubmissionId.make,
  $$null: CompanyFormSubmissionId.$$null,
  zero: CompanyFormSubmissionId.zero,
  $$Option: CompanyFormSubmissionId.$$Option,
  Comparator: CompanyFormSubmissionId.Comparator,
  Hasher: CompanyFormSubmissionId.Hasher,
  $$Map: CompanyFormSubmissionId.$$Map,
  $$Set: CompanyFormSubmissionId.$$Set,
  MutableSet: CompanyFormSubmissionId.MutableSet,
  Route: CompanyFormSubmissionId.Route,
  $$Array: CompanyFormSubmissionId.$$Array,
  SortArray: CompanyFormSubmissionId.SortArray
};

var Id$17 = T_Id$17;

function all$17(state) {
  return Id$17.$$Map.valuesToArray(state.draftCompanyFormSubmissions);
}

function forEach$17(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$17(state), fn);
}

function isEmpty$17(state) {
  return Id$17.$$Map.isEmpty(state.draftCompanyFormSubmissions);
}

function isNotEmpty$17(state) {
  return !isEmpty$17(state);
}

function update$17(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.draftCompanyFormSubmissions;
  var existing = Id$17.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$17.$$Map.set(collection, item.id, item);
  }
}

function updateMany$17(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$17(state, extra);
        }));
  return state;
}

function remove$17(state, item) {
  Id$17.$$Map.remove(state.draftCompanyFormSubmissions, item.id);
}

function get$17(state, key) {
  var collection = state.draftCompanyFormSubmissions;
  return Id$17.$$Map.get(collection, key);
}

function getActions$17(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.draftCompanyFormSubmissions;
  var $$final$1 = $$final.draftCompanyFormSubmissions;
  var removing = Id$17.$$Set.diff(Id$17.$$Set.fromArray(Id$17.$$Map.keysToArray(initial$1)), Id$17.$$Set.fromArray(Id$17.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$17.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$17.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$17.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$17(state) {
  Id$17.$$Map.clear(state.draftCompanyFormSubmissions);
}

var DraftCompanyFormSubmissions_Model = T_Model$17;

var DraftCompanyFormSubmissions = {
  Model: DraftCompanyFormSubmissions_Model,
  Id: Id$17,
  key: key$17,
  map: map$17,
  equal: undefined,
  all: all$17,
  get: get$17,
  update: update$17,
  updateMany: updateMany$17,
  remove: remove$17,
  getActions: getActions$17,
  forEach: forEach$17,
  clear: clear$17,
  isEmpty: isEmpty$17,
  isNotEmpty: isNotEmpty$17,
  getImages: getImages$17
};

function key$18(record) {
  return record.id;
}

function map$18(state) {
  return state.procedureUpdates;
}

function getImages$18(param) {
  return [];
}

var T_Model$18 = {
  clear: ProcedureUpdate.clear,
  save: ProcedureUpdate.save,
  $$delete: ProcedureUpdate.$$delete,
  remove: ProcedureUpdate.remove,
  IdComparator: ProcedureUpdate.IdComparator,
  Id: ProcedureUpdate.Id
};

var T_Id$18 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: ProcedureId.make,
  $$null: ProcedureId.$$null,
  zero: ProcedureId.zero,
  $$Option: ProcedureId.$$Option,
  Comparator: ProcedureId.Comparator,
  Hasher: ProcedureId.Hasher,
  $$Map: ProcedureId.$$Map,
  $$Set: ProcedureId.$$Set,
  MutableSet: ProcedureId.MutableSet,
  Route: ProcedureId.Route,
  $$Array: ProcedureId.$$Array,
  SortArray: ProcedureId.SortArray
};

var Id$18 = T_Id$18;

function all$18(state) {
  return Id$18.$$Map.valuesToArray(state.procedureUpdates);
}

function forEach$18(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$18(state), fn);
}

function isEmpty$18(state) {
  return Id$18.$$Map.isEmpty(state.procedureUpdates);
}

function isNotEmpty$18(state) {
  return !isEmpty$18(state);
}

function update$18(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.procedureUpdates;
  var existing = Id$18.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$18.$$Map.set(collection, item.id, item);
  }
}

function updateMany$18(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$18(state, extra);
        }));
  return state;
}

function remove$18(state, item) {
  Id$18.$$Map.remove(state.procedureUpdates, item.id);
}

function get$18(state, key) {
  var collection = state.procedureUpdates;
  return Id$18.$$Map.get(collection, key);
}

function getActions$18(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.procedureUpdates;
  var $$final$1 = $$final.procedureUpdates;
  var removing = Id$18.$$Set.diff(Id$18.$$Set.fromArray(Id$18.$$Map.keysToArray(initial$1)), Id$18.$$Set.fromArray(Id$18.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$18.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$18.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$18.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$18(state) {
  Id$18.$$Map.clear(state.procedureUpdates);
}

var ProcedureUpdates_Model = T_Model$18;

var ProcedureUpdates = {
  Model: ProcedureUpdates_Model,
  Id: Id$18,
  key: key$18,
  map: map$18,
  equal: undefined,
  all: all$18,
  get: get$18,
  update: update$18,
  updateMany: updateMany$18,
  remove: remove$18,
  getActions: getActions$18,
  forEach: forEach$18,
  clear: clear$18,
  isEmpty: isEmpty$18,
  isNotEmpty: isNotEmpty$18,
  getImages: getImages$18
};

function key$19(record) {
  return record.id;
}

function map$19(state) {
  return state.documents;
}

var equal$2 = (function (d1, d2) {
    return Caml_obj.equal(MarkdownDocument.Normalizer.normalize(d1), MarkdownDocument.Normalizer.normalize(d2));
  });

function getImages$19(state) {
  return MarkdownDocument.getImages(MarkdownDocumentId.$$Map.valuesToArray(state.documents));
}

var T_Model$19 = {
  clear: MarkdownDocument.clear,
  save: MarkdownDocument.save,
  $$delete: MarkdownDocument.$$delete,
  remove: MarkdownDocument.remove,
  IdComparator: MarkdownDocument.IdComparator,
  Id: MarkdownDocument.Id
};

var T_Id$19 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: MarkdownDocumentId.make,
  $$null: MarkdownDocumentId.$$null,
  zero: MarkdownDocumentId.zero,
  $$Option: MarkdownDocumentId.$$Option,
  Comparator: MarkdownDocumentId.Comparator,
  Hasher: MarkdownDocumentId.Hasher,
  $$Map: MarkdownDocumentId.$$Map,
  $$Set: MarkdownDocumentId.$$Set,
  MutableSet: MarkdownDocumentId.MutableSet,
  Route: MarkdownDocumentId.Route,
  $$Array: MarkdownDocumentId.$$Array,
  SortArray: MarkdownDocumentId.SortArray
};

var Id$19 = T_Id$19;

function all$19(state) {
  return Id$19.$$Map.valuesToArray(state.documents);
}

function forEach$19(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$19(state), fn);
}

function isEmpty$19(state) {
  return Id$19.$$Map.isEmpty(state.documents);
}

function isNotEmpty$19(state) {
  return !isEmpty$19(state);
}

function update$19(state, item) {
  var eq = Prelude.default(equal$2, Caml_obj.equal);
  var collection = state.documents;
  var existing = Id$19.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$19.$$Map.set(collection, item.id, item);
  }
}

function updateMany$19(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$19(state, extra);
        }));
  return state;
}

function remove$19(state, item) {
  Id$19.$$Map.remove(state.documents, item.id);
}

function get$19(state, key) {
  var collection = state.documents;
  return Id$19.$$Map.get(collection, key);
}

function getActions$19(initial, $$final) {
  var eq = Prelude.default(equal$2, Caml_obj.equal);
  var initial$1 = initial.documents;
  var $$final$1 = $$final.documents;
  var removing = Id$19.$$Set.diff(Id$19.$$Set.fromArray(Id$19.$$Map.keysToArray(initial$1)), Id$19.$$Set.fromArray(Id$19.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$19.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$19.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$19.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$19(state) {
  Id$19.$$Map.clear(state.documents);
}

var Documents_Model = T_Model$19;

var Documents = {
  Model: Documents_Model,
  Id: Id$19,
  key: key$19,
  map: map$19,
  equal: equal$2,
  all: all$19,
  get: get$19,
  update: update$19,
  updateMany: updateMany$19,
  remove: remove$19,
  getActions: getActions$19,
  forEach: forEach$19,
  clear: clear$19,
  isEmpty: isEmpty$19,
  isNotEmpty: isNotEmpty$19,
  getImages: getImages$19
};

function key$20(record) {
  return record.id;
}

function map$20(state) {
  return state.levels;
}

function getImages$20(param) {
  return [];
}

var T_Model$20 = {
  clear: Level.clear,
  save: Level.save,
  $$delete: Level.$$delete,
  remove: Level.remove,
  IdComparator: Level.IdComparator,
  Id: Level.Id
};

var T_Id$20 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: LevelId.make,
  $$null: LevelId.$$null,
  zero: LevelId.zero,
  $$Option: LevelId.$$Option,
  Comparator: LevelId.Comparator,
  Hasher: LevelId.Hasher,
  $$Map: LevelId.$$Map,
  $$Set: LevelId.$$Set,
  MutableSet: LevelId.MutableSet,
  Route: LevelId.Route,
  $$Array: LevelId.$$Array,
  SortArray: LevelId.SortArray
};

var Id$20 = T_Id$20;

function all$20(state) {
  return Id$20.$$Map.valuesToArray(state.levels);
}

function forEach$20(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$20(state), fn);
}

function isEmpty$20(state) {
  return Id$20.$$Map.isEmpty(state.levels);
}

function isNotEmpty$20(state) {
  return !isEmpty$20(state);
}

function update$20(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.levels;
  var existing = Id$20.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$20.$$Map.set(collection, item.id, item);
  }
}

function updateMany$20(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$20(state, extra);
        }));
  return state;
}

function remove$20(state, item) {
  Id$20.$$Map.remove(state.levels, item.id);
}

function get$20(state, key) {
  var collection = state.levels;
  return Id$20.$$Map.get(collection, key);
}

function getActions$20(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.levels;
  var $$final$1 = $$final.levels;
  var removing = Id$20.$$Set.diff(Id$20.$$Set.fromArray(Id$20.$$Map.keysToArray(initial$1)), Id$20.$$Set.fromArray(Id$20.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$20.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$20.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$20.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$20(state) {
  Id$20.$$Map.clear(state.levels);
}

var Levels_Model = T_Model$20;

var Levels = {
  Model: Levels_Model,
  Id: Id$20,
  key: key$20,
  map: map$20,
  equal: undefined,
  all: all$20,
  get: get$20,
  update: update$20,
  updateMany: updateMany$20,
  remove: remove$20,
  getActions: getActions$20,
  forEach: forEach$20,
  clear: clear$20,
  isEmpty: isEmpty$20,
  isNotEmpty: isNotEmpty$20,
  getImages: getImages$20
};

function key$21(record) {
  return record.id;
}

function map$21(state) {
  return state.levelTypes;
}

function getImages$21(param) {
  return [];
}

var T_Model$21 = {
  clear: LevelType.clear,
  save: LevelType.save,
  $$delete: LevelType.$$delete,
  remove: LevelType.remove,
  IdComparator: LevelType.IdComparator,
  Id: LevelType.Id
};

var T_Id$21 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: LevelTypeId.make,
  $$null: LevelTypeId.$$null,
  zero: LevelTypeId.zero,
  $$Option: LevelTypeId.$$Option,
  Comparator: LevelTypeId.Comparator,
  Hasher: LevelTypeId.Hasher,
  $$Map: LevelTypeId.$$Map,
  $$Set: LevelTypeId.$$Set,
  MutableSet: LevelTypeId.MutableSet,
  Route: LevelTypeId.Route,
  $$Array: LevelTypeId.$$Array,
  SortArray: LevelTypeId.SortArray
};

var Id$21 = T_Id$21;

function all$21(state) {
  return Id$21.$$Map.valuesToArray(state.levelTypes);
}

function forEach$21(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$21(state), fn);
}

function isEmpty$21(state) {
  return Id$21.$$Map.isEmpty(state.levelTypes);
}

function isNotEmpty$21(state) {
  return !isEmpty$21(state);
}

function update$21(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.levelTypes;
  var existing = Id$21.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$21.$$Map.set(collection, item.id, item);
  }
}

function updateMany$21(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$21(state, extra);
        }));
  return state;
}

function remove$21(state, item) {
  Id$21.$$Map.remove(state.levelTypes, item.id);
}

function get$21(state, key) {
  var collection = state.levelTypes;
  return Id$21.$$Map.get(collection, key);
}

function getActions$21(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.levelTypes;
  var $$final$1 = $$final.levelTypes;
  var removing = Id$21.$$Set.diff(Id$21.$$Set.fromArray(Id$21.$$Map.keysToArray(initial$1)), Id$21.$$Set.fromArray(Id$21.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$21.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$21.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$21.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$21(state) {
  Id$21.$$Map.clear(state.levelTypes);
}

var LevelTypes_Model = T_Model$21;

var LevelTypes = {
  Model: LevelTypes_Model,
  Id: Id$21,
  key: key$21,
  map: map$21,
  equal: undefined,
  all: all$21,
  get: get$21,
  update: update$21,
  updateMany: updateMany$21,
  remove: remove$21,
  getActions: getActions$21,
  forEach: forEach$21,
  clear: clear$21,
  isEmpty: isEmpty$21,
  isNotEmpty: isNotEmpty$21,
  getImages: getImages$21
};

function key$22(record) {
  return record.id;
}

function map$22(state) {
  return state.levelSubmissions;
}

function getImages$22(param) {
  return [];
}

var T_Model$22 = {
  clear: LevelSubmission.clear,
  save: LevelSubmission.save,
  $$delete: LevelSubmission.$$delete,
  remove: LevelSubmission.remove,
  IdComparator: LevelSubmission.IdComparator,
  Id: LevelSubmission.Id
};

var T_Id$22 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: LevelSubmissionId.make,
  $$null: LevelSubmissionId.$$null,
  zero: LevelSubmissionId.zero,
  $$Option: LevelSubmissionId.$$Option,
  Comparator: LevelSubmissionId.Comparator,
  Hasher: LevelSubmissionId.Hasher,
  $$Map: LevelSubmissionId.$$Map,
  $$Set: LevelSubmissionId.$$Set,
  MutableSet: LevelSubmissionId.MutableSet,
  Route: LevelSubmissionId.Route,
  $$Array: LevelSubmissionId.$$Array,
  SortArray: LevelSubmissionId.SortArray
};

var Id$22 = T_Id$22;

function all$22(state) {
  return Id$22.$$Map.valuesToArray(state.levelSubmissions);
}

function forEach$22(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$22(state), fn);
}

function isEmpty$22(state) {
  return Id$22.$$Map.isEmpty(state.levelSubmissions);
}

function isNotEmpty$22(state) {
  return !isEmpty$22(state);
}

function update$22(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.levelSubmissions;
  var existing = Id$22.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$22.$$Map.set(collection, item.id, item);
  }
}

function updateMany$22(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$22(state, extra);
        }));
  return state;
}

function remove$22(state, item) {
  Id$22.$$Map.remove(state.levelSubmissions, item.id);
}

function get$22(state, key) {
  var collection = state.levelSubmissions;
  return Id$22.$$Map.get(collection, key);
}

function getActions$22(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.levelSubmissions;
  var $$final$1 = $$final.levelSubmissions;
  var removing = Id$22.$$Set.diff(Id$22.$$Set.fromArray(Id$22.$$Map.keysToArray(initial$1)), Id$22.$$Set.fromArray(Id$22.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$22.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$22.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$22.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$22(state) {
  Id$22.$$Map.clear(state.levelSubmissions);
}

var LevelSubmissions_Model = T_Model$22;

var LevelSubmissions = {
  Model: LevelSubmissions_Model,
  Id: Id$22,
  key: key$22,
  map: map$22,
  equal: undefined,
  all: all$22,
  get: get$22,
  update: update$22,
  updateMany: updateMany$22,
  remove: remove$22,
  getActions: getActions$22,
  forEach: forEach$22,
  clear: clear$22,
  isEmpty: isEmpty$22,
  isNotEmpty: isNotEmpty$22,
  getImages: getImages$22
};

function key$23(record) {
  return record.id;
}

function map$23(state) {
  return state.newLevelSubmissions;
}

function getImages$23(param) {
  return [];
}

var T_Model$23 = {
  clear: LevelSubmission.clear,
  save: LevelSubmission.save,
  $$delete: LevelSubmission.$$delete,
  remove: LevelSubmission.remove,
  IdComparator: LevelSubmission.IdComparator,
  Id: LevelSubmission.Id
};

var T_Id$23 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: LevelSubmissionId.make,
  $$null: LevelSubmissionId.$$null,
  zero: LevelSubmissionId.zero,
  $$Option: LevelSubmissionId.$$Option,
  Comparator: LevelSubmissionId.Comparator,
  Hasher: LevelSubmissionId.Hasher,
  $$Map: LevelSubmissionId.$$Map,
  $$Set: LevelSubmissionId.$$Set,
  MutableSet: LevelSubmissionId.MutableSet,
  Route: LevelSubmissionId.Route,
  $$Array: LevelSubmissionId.$$Array,
  SortArray: LevelSubmissionId.SortArray
};

var Id$23 = T_Id$23;

function all$23(state) {
  return Id$23.$$Map.valuesToArray(state.newLevelSubmissions);
}

function forEach$23(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$23(state), fn);
}

function isEmpty$23(state) {
  return Id$23.$$Map.isEmpty(state.newLevelSubmissions);
}

function isNotEmpty$23(state) {
  return !isEmpty$23(state);
}

function update$23(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.newLevelSubmissions;
  var existing = Id$23.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$23.$$Map.set(collection, item.id, item);
  }
}

function updateMany$23(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$23(state, extra);
        }));
  return state;
}

function remove$23(state, item) {
  Id$23.$$Map.remove(state.newLevelSubmissions, item.id);
}

function get$23(state, key) {
  var collection = state.newLevelSubmissions;
  return Id$23.$$Map.get(collection, key);
}

function getActions$23(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.newLevelSubmissions;
  var $$final$1 = $$final.newLevelSubmissions;
  var removing = Id$23.$$Set.diff(Id$23.$$Set.fromArray(Id$23.$$Map.keysToArray(initial$1)), Id$23.$$Set.fromArray(Id$23.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$23.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$23.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$23.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$23(state) {
  Id$23.$$Map.clear(state.newLevelSubmissions);
}

var NewLevelSubmissions_Model = T_Model$23;

var NewLevelSubmissions = {
  Model: NewLevelSubmissions_Model,
  Id: Id$23,
  key: key$23,
  map: map$23,
  equal: undefined,
  all: all$23,
  get: get$23,
  update: update$23,
  updateMany: updateMany$23,
  remove: remove$23,
  getActions: getActions$23,
  forEach: forEach$23,
  clear: clear$23,
  isEmpty: isEmpty$23,
  isNotEmpty: isNotEmpty$23,
  getImages: getImages$23
};

function key$24(record) {
  return record.id;
}

function map$24(state) {
  return state.draftLevelSubmissions;
}

function getImages$24(param) {
  return [];
}

var T_Model$24 = {
  clear: LevelSubmission.clear,
  save: LevelSubmission.save,
  $$delete: LevelSubmission.$$delete,
  remove: LevelSubmission.remove,
  IdComparator: LevelSubmission.IdComparator,
  Id: LevelSubmission.Id
};

var T_Id$24 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: LevelSubmissionId.make,
  $$null: LevelSubmissionId.$$null,
  zero: LevelSubmissionId.zero,
  $$Option: LevelSubmissionId.$$Option,
  Comparator: LevelSubmissionId.Comparator,
  Hasher: LevelSubmissionId.Hasher,
  $$Map: LevelSubmissionId.$$Map,
  $$Set: LevelSubmissionId.$$Set,
  MutableSet: LevelSubmissionId.MutableSet,
  Route: LevelSubmissionId.Route,
  $$Array: LevelSubmissionId.$$Array,
  SortArray: LevelSubmissionId.SortArray
};

var Id$24 = T_Id$24;

function all$24(state) {
  return Id$24.$$Map.valuesToArray(state.draftLevelSubmissions);
}

function forEach$24(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$24(state), fn);
}

function isEmpty$24(state) {
  return Id$24.$$Map.isEmpty(state.draftLevelSubmissions);
}

function isNotEmpty$24(state) {
  return !isEmpty$24(state);
}

function update$24(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.draftLevelSubmissions;
  var existing = Id$24.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$24.$$Map.set(collection, item.id, item);
  }
}

function updateMany$24(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$24(state, extra);
        }));
  return state;
}

function remove$24(state, item) {
  Id$24.$$Map.remove(state.draftLevelSubmissions, item.id);
}

function get$24(state, key) {
  var collection = state.draftLevelSubmissions;
  return Id$24.$$Map.get(collection, key);
}

function getActions$24(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.draftLevelSubmissions;
  var $$final$1 = $$final.draftLevelSubmissions;
  var removing = Id$24.$$Set.diff(Id$24.$$Set.fromArray(Id$24.$$Map.keysToArray(initial$1)), Id$24.$$Set.fromArray(Id$24.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$24.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$24.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$24.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$24(state) {
  Id$24.$$Map.clear(state.draftLevelSubmissions);
}

var DraftLevelSubmissions_Model = T_Model$24;

var DraftLevelSubmissions = {
  Model: DraftLevelSubmissions_Model,
  Id: Id$24,
  key: key$24,
  map: map$24,
  equal: undefined,
  all: all$24,
  get: get$24,
  update: update$24,
  updateMany: updateMany$24,
  remove: remove$24,
  getActions: getActions$24,
  forEach: forEach$24,
  clear: clear$24,
  isEmpty: isEmpty$24,
  isNotEmpty: isNotEmpty$24,
  getImages: getImages$24
};

function key$25(record) {
  return record.id;
}

function map$25(state) {
  return state.questions;
}

function getImages$25(param) {
  return [];
}

var T_Model$25 = {
  clear: Question.clear,
  save: Question.save,
  $$delete: Question.$$delete,
  remove: Question.remove,
  IdComparator: Question.IdComparator,
  Id: Question.Id
};

var T_Id$25 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: QuestionId.make,
  $$null: QuestionId.$$null,
  zero: QuestionId.zero,
  $$Option: QuestionId.$$Option,
  Comparator: QuestionId.Comparator,
  Hasher: QuestionId.Hasher,
  $$Map: QuestionId.$$Map,
  $$Set: QuestionId.$$Set,
  MutableSet: QuestionId.MutableSet,
  Route: QuestionId.Route,
  $$Array: QuestionId.$$Array,
  SortArray: QuestionId.SortArray
};

var Id$25 = T_Id$25;

function all$25(state) {
  return Id$25.$$Map.valuesToArray(state.questions);
}

function forEach$25(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$25(state), fn);
}

function isEmpty$25(state) {
  return Id$25.$$Map.isEmpty(state.questions);
}

function isNotEmpty$25(state) {
  return !isEmpty$25(state);
}

function update$25(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.questions;
  var existing = Id$25.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$25.$$Map.set(collection, item.id, item);
  }
}

function updateMany$25(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$25(state, extra);
        }));
  return state;
}

function remove$25(state, item) {
  Id$25.$$Map.remove(state.questions, item.id);
}

function get$25(state, key) {
  var collection = state.questions;
  return Id$25.$$Map.get(collection, key);
}

function getActions$25(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.questions;
  var $$final$1 = $$final.questions;
  var removing = Id$25.$$Set.diff(Id$25.$$Set.fromArray(Id$25.$$Map.keysToArray(initial$1)), Id$25.$$Set.fromArray(Id$25.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$25.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$25.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$25.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$25(state) {
  Id$25.$$Map.clear(state.questions);
}

var Questions_Model = T_Model$25;

var Questions = {
  Model: Questions_Model,
  Id: Id$25,
  key: key$25,
  map: map$25,
  equal: undefined,
  all: all$25,
  get: get$25,
  update: update$25,
  updateMany: updateMany$25,
  remove: remove$25,
  getActions: getActions$25,
  forEach: forEach$25,
  clear: clear$25,
  isEmpty: isEmpty$25,
  isNotEmpty: isNotEmpty$25,
  getImages: getImages$25
};

function key$26(record) {
  return record.id;
}

function map$26(state) {
  return state.answers;
}

function getImages$26(state) {
  return Answer.getImages(AnswerId.$$Map.valuesToArray(state.answers));
}

var T_Model$26 = {
  clear: Answer.clear,
  save: Answer.save,
  $$delete: Answer.$$delete,
  remove: Answer.remove,
  IdComparator: Answer.IdComparator,
  Id: Answer.Id
};

var T_Id$26 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: AnswerId.make,
  $$null: AnswerId.$$null,
  zero: AnswerId.zero,
  $$Option: AnswerId.$$Option,
  Comparator: AnswerId.Comparator,
  Hasher: AnswerId.Hasher,
  $$Map: AnswerId.$$Map,
  $$Set: AnswerId.$$Set,
  MutableSet: AnswerId.MutableSet,
  Route: AnswerId.Route,
  $$Array: AnswerId.$$Array,
  SortArray: AnswerId.SortArray
};

var Id$26 = T_Id$26;

function all$26(state) {
  return Id$26.$$Map.valuesToArray(state.answers);
}

function forEach$26(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$26(state), fn);
}

function isEmpty$26(state) {
  return Id$26.$$Map.isEmpty(state.answers);
}

function isNotEmpty$26(state) {
  return !isEmpty$26(state);
}

function update$26(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.answers;
  var existing = Id$26.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$26.$$Map.set(collection, item.id, item);
  }
}

function updateMany$26(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$26(state, extra);
        }));
  return state;
}

function remove$26(state, item) {
  Id$26.$$Map.remove(state.answers, item.id);
}

function get$26(state, key) {
  var collection = state.answers;
  return Id$26.$$Map.get(collection, key);
}

function getActions$26(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.answers;
  var $$final$1 = $$final.answers;
  var removing = Id$26.$$Set.diff(Id$26.$$Set.fromArray(Id$26.$$Map.keysToArray(initial$1)), Id$26.$$Set.fromArray(Id$26.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$26.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$26.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$26.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$26(state) {
  Id$26.$$Map.clear(state.answers);
}

var Answers_Model = T_Model$26;

var Answers = {
  Model: Answers_Model,
  Id: Id$26,
  key: key$26,
  map: map$26,
  equal: undefined,
  all: all$26,
  get: get$26,
  update: update$26,
  updateMany: updateMany$26,
  remove: remove$26,
  getActions: getActions$26,
  forEach: forEach$26,
  clear: clear$26,
  isEmpty: isEmpty$26,
  isNotEmpty: isNotEmpty$26,
  getImages: getImages$26
};

function key$27(record) {
  return record.id;
}

function map$27(state) {
  return state.findings;
}

function getImages$27(param) {
  return [];
}

var T_Model$27 = {
  clear: Finding.clear,
  save: Finding.save,
  $$delete: Finding.$$delete,
  remove: Finding.remove,
  IdComparator: Finding.IdComparator,
  Id: Finding.Id
};

var T_Id$27 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: FindingId.make,
  $$null: FindingId.$$null,
  zero: FindingId.zero,
  $$Option: FindingId.$$Option,
  Comparator: FindingId.Comparator,
  Hasher: FindingId.Hasher,
  $$Map: FindingId.$$Map,
  $$Set: FindingId.$$Set,
  MutableSet: FindingId.MutableSet,
  Route: FindingId.Route,
  $$Array: FindingId.$$Array,
  SortArray: FindingId.SortArray
};

var Id$27 = T_Id$27;

function all$27(state) {
  return Id$27.$$Map.valuesToArray(state.findings);
}

function forEach$27(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$27(state), fn);
}

function isEmpty$27(state) {
  return Id$27.$$Map.isEmpty(state.findings);
}

function isNotEmpty$27(state) {
  return !isEmpty$27(state);
}

function update$27(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.findings;
  var existing = Id$27.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$27.$$Map.set(collection, item.id, item);
  }
}

function updateMany$27(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$27(state, extra);
        }));
  return state;
}

function remove$27(state, item) {
  Id$27.$$Map.remove(state.findings, item.id);
}

function get$27(state, key) {
  var collection = state.findings;
  return Id$27.$$Map.get(collection, key);
}

function getActions$27(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.findings;
  var $$final$1 = $$final.findings;
  var removing = Id$27.$$Set.diff(Id$27.$$Set.fromArray(Id$27.$$Map.keysToArray(initial$1)), Id$27.$$Set.fromArray(Id$27.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$27.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$27.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$27.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$27(state) {
  Id$27.$$Map.clear(state.findings);
}

var Findings_Model = T_Model$27;

var Findings = {
  Model: Findings_Model,
  Id: Id$27,
  key: key$27,
  map: map$27,
  equal: undefined,
  all: all$27,
  get: get$27,
  update: update$27,
  updateMany: updateMany$27,
  remove: remove$27,
  getActions: getActions$27,
  forEach: forEach$27,
  clear: clear$27,
  isEmpty: isEmpty$27,
  isNotEmpty: isNotEmpty$27,
  getImages: getImages$27
};

function key$28(record) {
  return record.id;
}

function map$28(state) {
  return state.notificationEntries;
}

function getImages$28(param) {
  return [];
}

var T_Model$28 = {
  clear: NotificationEntry.clear,
  save: NotificationEntry.save,
  $$delete: NotificationEntry.$$delete,
  remove: NotificationEntry.remove,
  IdComparator: NotificationEntry.IdComparator,
  Id: NotificationEntry.Id
};

var T_Id$28 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: NotificationEntryId.make,
  $$null: NotificationEntryId.$$null,
  zero: NotificationEntryId.zero,
  $$Option: NotificationEntryId.$$Option,
  Comparator: NotificationEntryId.Comparator,
  Hasher: NotificationEntryId.Hasher,
  $$Map: NotificationEntryId.$$Map,
  $$Set: NotificationEntryId.$$Set,
  MutableSet: NotificationEntryId.MutableSet,
  Route: NotificationEntryId.Route,
  $$Array: NotificationEntryId.$$Array,
  SortArray: NotificationEntryId.SortArray
};

var Id$28 = T_Id$28;

function all$28(state) {
  return Id$28.$$Map.valuesToArray(state.notificationEntries);
}

function forEach$28(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$28(state), fn);
}

function isEmpty$28(state) {
  return Id$28.$$Map.isEmpty(state.notificationEntries);
}

function isNotEmpty$28(state) {
  return !isEmpty$28(state);
}

function update$28(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.notificationEntries;
  var existing = Id$28.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$28.$$Map.set(collection, item.id, item);
  }
}

function updateMany$28(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$28(state, extra);
        }));
  return state;
}

function remove$28(state, item) {
  Id$28.$$Map.remove(state.notificationEntries, item.id);
}

function get$28(state, key) {
  var collection = state.notificationEntries;
  return Id$28.$$Map.get(collection, key);
}

function getActions$28(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.notificationEntries;
  var $$final$1 = $$final.notificationEntries;
  var removing = Id$28.$$Set.diff(Id$28.$$Set.fromArray(Id$28.$$Map.keysToArray(initial$1)), Id$28.$$Set.fromArray(Id$28.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$28.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$28.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$28.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$28(state) {
  Id$28.$$Map.clear(state.notificationEntries);
}

var NotificationEntries_Model = T_Model$28;

var NotificationEntries = {
  Model: NotificationEntries_Model,
  Id: Id$28,
  key: key$28,
  map: map$28,
  equal: undefined,
  all: all$28,
  get: get$28,
  update: update$28,
  updateMany: updateMany$28,
  remove: remove$28,
  getActions: getActions$28,
  forEach: forEach$28,
  clear: clear$28,
  isEmpty: isEmpty$28,
  isNotEmpty: isNotEmpty$28,
  getImages: getImages$28
};

function key$29(record) {
  return record.id;
}

function map$29(state) {
  return state.readNotificationEntries;
}

function getImages$29(param) {
  return [];
}

var T_Model$29 = {
  clear: ReadNotificationEntry.clear,
  save: ReadNotificationEntry.save,
  $$delete: ReadNotificationEntry.$$delete,
  remove: ReadNotificationEntry.remove,
  IdComparator: ReadNotificationEntry.IdComparator,
  Id: ReadNotificationEntry.Id
};

var T_Id$29 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: NotificationEntryId.make,
  $$null: NotificationEntryId.$$null,
  zero: NotificationEntryId.zero,
  $$Option: NotificationEntryId.$$Option,
  Comparator: NotificationEntryId.Comparator,
  Hasher: NotificationEntryId.Hasher,
  $$Map: NotificationEntryId.$$Map,
  $$Set: NotificationEntryId.$$Set,
  MutableSet: NotificationEntryId.MutableSet,
  Route: NotificationEntryId.Route,
  $$Array: NotificationEntryId.$$Array,
  SortArray: NotificationEntryId.SortArray
};

var Id$29 = T_Id$29;

function all$29(state) {
  return Id$29.$$Map.valuesToArray(state.readNotificationEntries);
}

function forEach$29(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$29(state), fn);
}

function isEmpty$29(state) {
  return Id$29.$$Map.isEmpty(state.readNotificationEntries);
}

function isNotEmpty$29(state) {
  return !isEmpty$29(state);
}

function update$29(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.readNotificationEntries;
  var existing = Id$29.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$29.$$Map.set(collection, item.id, item);
  }
}

function updateMany$29(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$29(state, extra);
        }));
  return state;
}

function remove$29(state, item) {
  Id$29.$$Map.remove(state.readNotificationEntries, item.id);
}

function get$29(state, key) {
  var collection = state.readNotificationEntries;
  return Id$29.$$Map.get(collection, key);
}

function getActions$29(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.readNotificationEntries;
  var $$final$1 = $$final.readNotificationEntries;
  var removing = Id$29.$$Set.diff(Id$29.$$Set.fromArray(Id$29.$$Map.keysToArray(initial$1)), Id$29.$$Set.fromArray(Id$29.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$29.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$29.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$29.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$29(state) {
  Id$29.$$Map.clear(state.readNotificationEntries);
}

var ReadNotificationEntries_Model = T_Model$29;

var ReadNotificationEntries = {
  Model: ReadNotificationEntries_Model,
  Id: Id$29,
  key: key$29,
  map: map$29,
  equal: undefined,
  all: all$29,
  get: get$29,
  update: update$29,
  updateMany: updateMany$29,
  remove: remove$29,
  getActions: getActions$29,
  forEach: forEach$29,
  clear: clear$29,
  isEmpty: isEmpty$29,
  isNotEmpty: isNotEmpty$29,
  getImages: getImages$29
};

function key$30(record) {
  return record.id;
}

function map$30(state) {
  return state.vessels;
}

function getImages$30(state) {
  return Vessel.getImages(VesselId.$$Map.valuesToArray(state.vessels));
}

var T_Model$30 = {
  clear: Vessel.clear,
  save: Vessel.save,
  $$delete: Vessel.$$delete,
  remove: Vessel.remove,
  IdComparator: Vessel.IdComparator,
  Id: Vessel.Id
};

var T_Id$30 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: VesselId.make,
  $$null: VesselId.$$null,
  zero: VesselId.zero,
  $$Option: VesselId.$$Option,
  Comparator: VesselId.Comparator,
  Hasher: VesselId.Hasher,
  $$Map: VesselId.$$Map,
  $$Set: VesselId.$$Set,
  MutableSet: VesselId.MutableSet,
  Route: VesselId.Route,
  $$Array: VesselId.$$Array,
  SortArray: VesselId.SortArray
};

var Id$30 = T_Id$30;

function all$30(state) {
  return Id$30.$$Map.valuesToArray(state.vessels);
}

function forEach$30(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$30(state), fn);
}

function isEmpty$30(state) {
  return Id$30.$$Map.isEmpty(state.vessels);
}

function isNotEmpty$30(state) {
  return !isEmpty$30(state);
}

function update$30(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.vessels;
  var existing = Id$30.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$30.$$Map.set(collection, item.id, item);
  }
}

function updateMany$30(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$30(state, extra);
        }));
  return state;
}

function remove$30(state, item) {
  Id$30.$$Map.remove(state.vessels, item.id);
}

function get$30(state, key) {
  var collection = state.vessels;
  return Id$30.$$Map.get(collection, key);
}

function getActions$30(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.vessels;
  var $$final$1 = $$final.vessels;
  var removing = Id$30.$$Set.diff(Id$30.$$Set.fromArray(Id$30.$$Map.keysToArray(initial$1)), Id$30.$$Set.fromArray(Id$30.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$30.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$30.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$30.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$30(state) {
  Id$30.$$Map.clear(state.vessels);
}

var Vessels_Model = T_Model$30;

var Vessels = {
  Model: Vessels_Model,
  Id: Id$30,
  key: key$30,
  map: map$30,
  equal: undefined,
  all: all$30,
  get: get$30,
  update: update$30,
  updateMany: updateMany$30,
  remove: remove$30,
  getActions: getActions$30,
  forEach: forEach$30,
  clear: clear$30,
  isEmpty: isEmpty$30,
  isNotEmpty: isNotEmpty$30,
  getImages: getImages$30
};

function key$31(record) {
  return record.id;
}

function map$31(state) {
  return state.futureAttachments;
}

function getImages$31(state) {
  return FutureAttachment.getImages(AttachmentId.$$Map.valuesToArray(state.futureAttachments));
}

var T_Model$31 = {
  clear: FutureAttachment.clear,
  save: FutureAttachment.save,
  $$delete: FutureAttachment.$$delete,
  remove: FutureAttachment.remove,
  IdComparator: FutureAttachment.IdComparator,
  Id: FutureAttachment.Id
};

var T_Id$31 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: AttachmentId.make,
  $$null: AttachmentId.$$null,
  zero: AttachmentId.zero,
  $$Option: AttachmentId.$$Option,
  Comparator: AttachmentId.Comparator,
  Hasher: AttachmentId.Hasher,
  $$Map: AttachmentId.$$Map,
  $$Set: AttachmentId.$$Set,
  MutableSet: AttachmentId.MutableSet,
  Route: AttachmentId.Route,
  $$Array: AttachmentId.$$Array,
  SortArray: AttachmentId.SortArray
};

var Id$31 = T_Id$31;

function all$31(state) {
  return Id$31.$$Map.valuesToArray(state.futureAttachments);
}

function forEach$31(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$31(state), fn);
}

function isEmpty$31(state) {
  return Id$31.$$Map.isEmpty(state.futureAttachments);
}

function isNotEmpty$31(state) {
  return !isEmpty$31(state);
}

function update$31(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.futureAttachments;
  var existing = Id$31.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$31.$$Map.set(collection, item.id, item);
  }
}

function updateMany$31(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$31(state, extra);
        }));
  return state;
}

function remove$31(state, item) {
  Id$31.$$Map.remove(state.futureAttachments, item.id);
}

function get$31(state, key) {
  var collection = state.futureAttachments;
  return Id$31.$$Map.get(collection, key);
}

function getActions$31(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.futureAttachments;
  var $$final$1 = $$final.futureAttachments;
  var removing = Id$31.$$Set.diff(Id$31.$$Set.fromArray(Id$31.$$Map.keysToArray(initial$1)), Id$31.$$Set.fromArray(Id$31.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$31.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$31.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$31.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$31(state) {
  Id$31.$$Map.clear(state.futureAttachments);
}

var FutureAttachments_Model = T_Model$31;

var FutureAttachments = {
  Model: FutureAttachments_Model,
  Id: Id$31,
  key: key$31,
  map: map$31,
  equal: undefined,
  all: all$31,
  get: get$31,
  update: update$31,
  updateMany: updateMany$31,
  remove: remove$31,
  getActions: getActions$31,
  forEach: forEach$31,
  clear: clear$31,
  isEmpty: isEmpty$31,
  isNotEmpty: isNotEmpty$31,
  getImages: getImages$31
};

function key$32(record) {
  return record.id;
}

function map$32(state) {
  return state.sireVettingPeriods;
}

var equal$3 = (function (i1, i2) {
    return Caml_obj.equal(i1.id, i2.id);
  });

function getImages$32(param) {
  return [];
}

var T_Model$32 = {
  clear: SireVettingPeriod.clear,
  save: SireVettingPeriod.save,
  $$delete: SireVettingPeriod.$$delete,
  remove: SireVettingPeriod.remove,
  IdComparator: SireVettingPeriod.IdComparator,
  Id: SireVettingPeriod.Id
};

var T_Id$32 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: SireVettingPeriodId.make,
  $$null: SireVettingPeriodId.$$null,
  zero: SireVettingPeriodId.zero,
  $$Option: SireVettingPeriodId.$$Option,
  Comparator: SireVettingPeriodId.Comparator,
  Hasher: SireVettingPeriodId.Hasher,
  $$Map: SireVettingPeriodId.$$Map,
  $$Set: SireVettingPeriodId.$$Set,
  MutableSet: SireVettingPeriodId.MutableSet,
  Route: SireVettingPeriodId.Route,
  $$Array: SireVettingPeriodId.$$Array,
  SortArray: SireVettingPeriodId.SortArray
};

var Id$32 = T_Id$32;

function all$32(state) {
  return Id$32.$$Map.valuesToArray(state.sireVettingPeriods);
}

function forEach$32(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$32(state), fn);
}

function isEmpty$32(state) {
  return Id$32.$$Map.isEmpty(state.sireVettingPeriods);
}

function isNotEmpty$32(state) {
  return !isEmpty$32(state);
}

function update$32(state, item) {
  var eq = Prelude.default(equal$3, Caml_obj.equal);
  var collection = state.sireVettingPeriods;
  var existing = Id$32.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$32.$$Map.set(collection, item.id, item);
  }
}

function updateMany$32(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$32(state, extra);
        }));
  return state;
}

function remove$32(state, item) {
  Id$32.$$Map.remove(state.sireVettingPeriods, item.id);
}

function get$32(state, key) {
  var collection = state.sireVettingPeriods;
  return Id$32.$$Map.get(collection, key);
}

function getActions$32(initial, $$final) {
  var eq = Prelude.default(equal$3, Caml_obj.equal);
  var initial$1 = initial.sireVettingPeriods;
  var $$final$1 = $$final.sireVettingPeriods;
  var removing = Id$32.$$Set.diff(Id$32.$$Set.fromArray(Id$32.$$Map.keysToArray(initial$1)), Id$32.$$Set.fromArray(Id$32.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$32.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$32.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$32.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$32(state) {
  Id$32.$$Map.clear(state.sireVettingPeriods);
}

var SireVettingPeriods_Model = T_Model$32;

var SireVettingPeriods = {
  Model: SireVettingPeriods_Model,
  Id: Id$32,
  key: key$32,
  map: map$32,
  equal: equal$3,
  all: all$32,
  get: get$32,
  update: update$32,
  updateMany: updateMany$32,
  remove: remove$32,
  getActions: getActions$32,
  forEach: forEach$32,
  clear: clear$32,
  isEmpty: isEmpty$32,
  isNotEmpty: isNotEmpty$32,
  getImages: getImages$32
};

function key$33(record) {
  return record.id;
}

function map$33(state) {
  return state.sire2VettingPeriods;
}

function getImages$33(param) {
  return [];
}

var T_Model$33 = {
  clear: Sire2VettingPeriod.clear,
  save: Sire2VettingPeriod.save,
  $$delete: Sire2VettingPeriod.$$delete,
  remove: Sire2VettingPeriod.remove,
  IdComparator: Sire2VettingPeriod.IdComparator,
  Id: Sire2VettingPeriod.Id
};

var T_Id$33 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: Sire2VettingPeriodId.make,
  $$null: Sire2VettingPeriodId.$$null,
  zero: Sire2VettingPeriodId.zero,
  $$Option: Sire2VettingPeriodId.$$Option,
  Comparator: Sire2VettingPeriodId.Comparator,
  Hasher: Sire2VettingPeriodId.Hasher,
  $$Map: Sire2VettingPeriodId.$$Map,
  $$Set: Sire2VettingPeriodId.$$Set,
  MutableSet: Sire2VettingPeriodId.MutableSet,
  Route: Sire2VettingPeriodId.Route,
  $$Array: Sire2VettingPeriodId.$$Array,
  SortArray: Sire2VettingPeriodId.SortArray
};

var Id$33 = T_Id$33;

function all$33(state) {
  return Id$33.$$Map.valuesToArray(state.sire2VettingPeriods);
}

function forEach$33(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$33(state), fn);
}

function isEmpty$33(state) {
  return Id$33.$$Map.isEmpty(state.sire2VettingPeriods);
}

function isNotEmpty$33(state) {
  return !isEmpty$33(state);
}

function update$33(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.sire2VettingPeriods;
  var existing = Id$33.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$33.$$Map.set(collection, item.id, item);
  }
}

function updateMany$33(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$33(state, extra);
        }));
  return state;
}

function remove$33(state, item) {
  Id$33.$$Map.remove(state.sire2VettingPeriods, item.id);
}

function get$33(state, key) {
  var collection = state.sire2VettingPeriods;
  return Id$33.$$Map.get(collection, key);
}

function getActions$33(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.sire2VettingPeriods;
  var $$final$1 = $$final.sire2VettingPeriods;
  var removing = Id$33.$$Set.diff(Id$33.$$Set.fromArray(Id$33.$$Map.keysToArray(initial$1)), Id$33.$$Set.fromArray(Id$33.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$33.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$33.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$33.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$33(state) {
  Id$33.$$Map.clear(state.sire2VettingPeriods);
}

var Sire2VettingPeriods_Model = T_Model$33;

var Sire2VettingPeriods = {
  Model: Sire2VettingPeriods_Model,
  Id: Id$33,
  key: key$33,
  map: map$33,
  equal: undefined,
  all: all$33,
  get: get$33,
  update: update$33,
  updateMany: updateMany$33,
  remove: remove$33,
  getActions: getActions$33,
  forEach: forEach$33,
  clear: clear$33,
  isEmpty: isEmpty$33,
  isNotEmpty: isNotEmpty$33,
  getImages: getImages$33
};

function key$34(record) {
  return record.id;
}

function map$34(state) {
  return state.sire2RoviqLocations;
}

var equal$4 = (function (i1, i2) {
    if (Caml_obj.equal(i1.id, i2.id)) {
      return Caml_obj.equal(i1.locations, i2.locations);
    } else {
      return false;
    }
  });

function getImages$34(param) {
  return [];
}

var T_Model$34 = {
  clear: Sire2RoviqLocations.clear,
  save: Sire2RoviqLocations.save,
  $$delete: Sire2RoviqLocations.$$delete,
  remove: Sire2RoviqLocations.remove,
  IdComparator: Sire2RoviqLocations.IdComparator,
  Id: Sire2RoviqLocations.Id
};

var T_Id$34 = {
  fromString: (function (prim) {
      return prim;
    }),
  toString: (function (prim) {
      return prim;
    }),
  manyFromString: (function (prim) {
      return prim;
    }),
  manyToString: (function (prim) {
      return prim;
    }),
  make: Sire2RoviqLocations.Identifier.make,
  $$null: Sire2RoviqLocations.Identifier.$$null,
  zero: Sire2RoviqLocations.Identifier.zero,
  $$Option: Sire2RoviqLocations.Identifier.$$Option,
  Comparator: Sire2RoviqLocations.Identifier.Comparator,
  Hasher: Sire2RoviqLocations.Identifier.Hasher,
  $$Map: Sire2RoviqLocations.Identifier.$$Map,
  $$Set: Sire2RoviqLocations.Identifier.$$Set,
  MutableSet: Sire2RoviqLocations.Identifier.MutableSet,
  Route: Sire2RoviqLocations.Identifier.Route,
  $$Array: Sire2RoviqLocations.Identifier.$$Array,
  SortArray: Sire2RoviqLocations.Identifier.SortArray
};

var Id$34 = T_Id$34;

function all$34(state) {
  return Id$34.$$Map.valuesToArray(state.sire2RoviqLocations);
}

function forEach$34(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$34(state), fn);
}

function isEmpty$34(state) {
  return Id$34.$$Map.isEmpty(state.sire2RoviqLocations);
}

function isNotEmpty$34(state) {
  return !isEmpty$34(state);
}

function update$34(state, item) {
  var eq = Prelude.default(equal$4, Caml_obj.equal);
  var collection = state.sire2RoviqLocations;
  var existing = Id$34.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$34.$$Map.set(collection, item.id, item);
  }
}

function updateMany$34(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$34(state, extra);
        }));
  return state;
}

function remove$34(state, item) {
  Id$34.$$Map.remove(state.sire2RoviqLocations, item.id);
}

function get$34(state, key) {
  var collection = state.sire2RoviqLocations;
  return Id$34.$$Map.get(collection, key);
}

function getActions$34(initial, $$final) {
  var eq = Prelude.default(equal$4, Caml_obj.equal);
  var initial$1 = initial.sire2RoviqLocations;
  var $$final$1 = $$final.sire2RoviqLocations;
  var removing = Id$34.$$Set.diff(Id$34.$$Set.fromArray(Id$34.$$Map.keysToArray(initial$1)), Id$34.$$Set.fromArray(Id$34.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$34.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$34.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$34.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$34(state) {
  Id$34.$$Map.clear(state.sire2RoviqLocations);
}

var Sire2RoviqLocations_Model = T_Model$34;

var Sire2RoviqLocations$1 = {
  Model: Sire2RoviqLocations_Model,
  Id: Id$34,
  key: key$34,
  map: map$34,
  equal: equal$4,
  all: all$34,
  get: get$34,
  update: update$34,
  updateMany: updateMany$34,
  remove: remove$34,
  getActions: getActions$34,
  forEach: forEach$34,
  clear: clear$34,
  isEmpty: isEmpty$34,
  isNotEmpty: isNotEmpty$34,
  getImages: getImages$34
};

function MakeNonDBView(T) {
  var Id = T.Id;
  var map = T.map;
  var all = function (state) {
    return Id.$$Map.valuesToArray(T.map(state));
  };
  var forEach = function (state, fn) {
    Curry._2(Prelude.$$Array.forEach, all(state), fn);
  };
  var update = function (state, item) {
    var eq = Prelude.default(T.equal, Caml_obj.equal);
    var collection = T.map(state);
    var existing = Id.$$Map.get(collection, T.key(item));
    if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
      return ;
    } else {
      return Id.$$Map.set(collection, T.key(item), item);
    }
  };
  var updateMany = function (state, items) {
    Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
            return update(state, extra);
          }));
    return state;
  };
  var remove = function (state, item) {
    Id.$$Map.remove(T.map(state), T.key(item));
  };
  var get = function (state, key) {
    var collection = T.map(state);
    return Id.$$Map.get(collection, key);
  };
  var getActions = function (initial, $$final) {
    var eq = Prelude.default(T.equal, Caml_obj.equal);
    var initial$1 = T.map(initial);
    var $$final$1 = T.map($$final);
    var removing = Id.$$Set.diff(Id.$$Set.fromArray(Id.$$Map.keysToArray(initial$1)), Id.$$Set.fromArray(Id.$$Map.keysToArray($$final$1)));
    var $$new = Curry._2(Prelude.$$Array.keepMap, Id.$$Map.toArray($$final$1), (function (param) {
            var value = param[1];
            var current = Id.$$Map.get(initial$1, param[0]);
            if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
              return ;
            } else {
              return Caml_option.some(value);
            }
          }));
    return Curry._2(Prelude.$$Array.concat, Id.$$Set.toArray(removing).map(function (id) {
                    return {
                            TAG: "Delete",
                            _0: id
                          };
                  }), $$new.map(function (r) {
                    return {
                            TAG: "Update",
                            _0: r
                          };
                  }));
  };
  var clear = function (state) {
    Id.$$Map.clear(map(state));
  };
  return {
          Id: Id,
          key: T.key,
          map: map,
          equal: T.equal,
          all: all,
          get: get,
          update: update,
          updateMany: updateMany,
          remove: remove,
          forEach: forEach,
          clear: clear,
          getActions: getActions
        };
}

function key$35(record) {
  return record.id;
}

function map$35(state) {
  return state.files;
}

var equal$5 = (function (r1, r2) {
    return Caml_obj.equal(r1.id, r2.id);
  });

function all$35(state) {
  return LocalFile.UUID.$$Map.valuesToArray(state.files);
}

function forEach$35(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$35(state), fn);
}

function update$35(state, item) {
  var eq = Prelude.default(equal$5, Caml_obj.equal);
  var collection = state.files;
  var existing = LocalFile.UUID.$$Map.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return LocalFile.UUID.$$Map.set(collection, item.id, item);
  }
}

function updateMany$35(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (extra) {
          return update$35(state, extra);
        }));
  return state;
}

function remove$35(state, item) {
  LocalFile.UUID.$$Map.remove(state.files, item.id);
}

function get$35(state, key) {
  var collection = state.files;
  return LocalFile.UUID.$$Map.get(collection, key);
}

function getActions$35(initial, $$final) {
  var eq = Prelude.default(equal$5, Caml_obj.equal);
  var initial$1 = initial.files;
  var $$final$1 = $$final.files;
  var removing = LocalFile.UUID.$$Set.diff(LocalFile.UUID.$$Set.fromArray(LocalFile.UUID.$$Map.keysToArray(initial$1)), LocalFile.UUID.$$Set.fromArray(LocalFile.UUID.$$Map.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, LocalFile.UUID.$$Map.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = LocalFile.UUID.$$Map.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, LocalFile.UUID.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Update",
                          _0: r
                        };
                }));
}

function clear$35(state) {
  LocalFile.UUID.$$Map.clear(state.files);
}

var Files = {
  Id: LocalFile.UUID,
  key: key$35,
  map: map$35,
  equal: equal$5,
  all: all$35,
  get: get$35,
  update: update$35,
  updateMany: updateMany$35,
  remove: remove$35,
  forEach: forEach$35,
  clear: clear$35,
  getActions: getActions$35
};

export {
  Core ,
  make ,
  MakeView ,
  Sections ,
  Components ,
  Procedures ,
  PlannedProcedures ,
  Inspections ,
  NewInspections ,
  DraftInspections ,
  Deficiencies ,
  RatingRules ,
  Comments ,
  UpdatedComments ,
  UserProfiles ,
  Staffers ,
  R4SChecklist$1 as R4SChecklist,
  CompanyForms ,
  CompanyFormSubmissions ,
  NewCompanyFormSubmissions ,
  DraftCompanyFormSubmissions ,
  ProcedureUpdates ,
  Documents ,
  Levels ,
  LevelTypes ,
  LevelSubmissions ,
  NewLevelSubmissions ,
  DraftLevelSubmissions ,
  Questions ,
  Answers ,
  Findings ,
  NotificationEntries ,
  ReadNotificationEntries ,
  Vessels ,
  FutureAttachments ,
  SireVettingPeriods ,
  Sire2VettingPeriods ,
  Sire2RoviqLocations$1 as Sire2RoviqLocations,
  MakeNonDBView ,
  Files ,
}
/* Level Not a pure module */
