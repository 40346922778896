// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../InitFeedback.js";
import * as IDB__Migration__Store from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Store.js";
import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

function message() {
  return "Creating Sire 2 Roviq locations store";
}

async function migrate(db, _transaction) {
  IDB__Migration__Store.createSimpleMultiEntryIndex(IDB__Migration__Database.createStandardStore(db, "sire2RoviqLocations", [
            "vessel_id",
            "question_id",
            "level_id"
          ]), "locations");
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var include = InitFeedback.MakeMigration({
      message: message,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* include Not a pure module */
