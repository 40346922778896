// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Utils from "../../libs/Utils.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";

var Identifier = Utils.MakeOpaqueIdentifier({});

var _map = {"anywhere":"anywhere","aft_mooring_deck":"aft mooring deck","approaching_vessel":"approaching vessel","bow_loading_area":"bow loading area","bridge":"bridge","cargo_control_room":"cargo control room","cargo_manifold":"cargo manifold","chief_engineer_office":"chief engineer's office","compressor_room":"compressor room","documentation":"documentation","emergency_headquarters":"emergency headquarters","engine_control_room":"engine control room","engine_room":"engine room","exterior_decks":"exterior decks","forecastle":"forecastle","internal_accommodation":"internal accommodation","interview_deck_officer":"interview - deck officer","interview_deck_rating":"interview - deck rating","interview_electrician_eto":"interview - electrician / eto","interview_engine_rating":"interview - engine rating","interview_engineer_officer":"interview - engineer officer","interview_galley_rating":"interview - galley rating","interview_rating":"interview - rating","interview_security_officer":"interview - security officer","interview_senior_officer":"interview - senior officer","lifeboat_deck":"lifeboat deck","main_deck":"main deck","mooring_decks":"mooring decks","pre_board":"pre-board","pumproom":"pumproom","steering_gear":"steering gear"};

var _revMap = {"anywhere":"anywhere","aft mooring deck":"aft_mooring_deck","approaching vessel":"approaching_vessel","bow loading area":"bow_loading_area","bridge":"bridge","cargo control room":"cargo_control_room","cargo manifold":"cargo_manifold","chief engineer's office":"chief_engineer_office","compressor room":"compressor_room","documentation":"documentation","emergency headquarters":"emergency_headquarters","engine control room":"engine_control_room","engine room":"engine_room","exterior decks":"exterior_decks","forecastle":"forecastle","internal accommodation":"internal_accommodation","interview - deck officer":"interview_deck_officer","interview - deck rating":"interview_deck_rating","interview - electrician / eto":"interview_electrician_eto","interview - engine rating":"interview_engine_rating","interview - engineer officer":"interview_engineer_officer","interview - galley rating":"interview_galley_rating","interview - rating":"interview_rating","interview - security officer":"interview_security_officer","interview - senior officer":"interview_senior_officer","lifeboat deck":"lifeboat_deck","main deck":"main_deck","mooring decks":"mooring_decks","pre-board":"pre_board","pumproom":"pumproom","steering gear":"steering_gear"};

function tToJs(param) {
  return _map[param];
}

function tFromJs(param) {
  return _revMap[param];
}

var deserFromJS = $$JSON.Field.variadicString("Expected a valid Roviq locations", (function (x) {
        return _revMap[x];
      }));

var Deserializer = $$JSON.MakeDeserializer({
      fields: deserFromJS
    });

var RoviqLocations = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  deserFromJS: deserFromJS,
  Deserializer: Deserializer
};

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  RoviqLocations ,
  Definition ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
}
/* Identifier Not a pure module */
